import { Col, Row } from "antd";
import StarterCard from "../../components/V2/ProfileV2/StarterCard";
import StorageShipmentList from "../../components/V2/StorageV2/StorageShipmentList";

const StoragePageV2 = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <StarterCard/>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <StorageShipmentList/>
        </Col>
      </Row>
    </>
  );
};

export default StoragePageV2;
