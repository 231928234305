import {
  Button,
  Card,
  Col,
  Image,
  Form,
  Input,
  Row,
  message,
  Empty,
  Modal,
  Alert,
  Progress,
  Tooltip,
  Badge,
} from "antd";
import ProgressBar from "../../components/V2/InventoryShipmentV2/ProgressBar";
import ProductService from "../../services/ProductService";
import { openNotification } from "../../utils";
import { IProductResponse } from "../../types/Product";
import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import InventoryShipmentCard from "../../components/V2/InventoryShipmentV2/InventoryShipmentCard";
import StarterCard from "../../components/V2/ProfileV2/StarterCard";
import FbaImg from "../../assets/dashboard-v2/fba-quickaccess.png";
import { ReactComponent as InfoIcon } from "../../assets/info-icon.svg";
import OneamzAppService from "../../services/OneamzAppService";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning-icon.svg";
import WarehouseCard from "../../components/FBACreate/WarehouseCard";
import dayjs from "dayjs";
import StorageResultItemCard from "./OrderDetail/StorageResultItemCard";
import IncomingInventoryService from "../../services/IncomingInventoryService";
import { IIncomingInventoryResponse } from "../../types/IncomingInventory";

const InventoryNewV2 = () => {
  // const navigate = useNavigate();
  const [productLoad, setProductLoad] = useState(false);
  const [asinFind] = Form.useForm();
  const [orderItemList, setOrderItemList] = useState<IProductResponse[]>([]);
  const [totalArea, setTotalArea] = useState(0);
  const [totaPrice, setTotalPrice] = useState<any>(0);
  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [orderCreated, setOrderCreated] = useState(false);
  // eslint-disable-next-line
  const [currentBalance, setCurrentBalance] = useState(0);
  const [loadBalance, setLoadBalance] = useState<any>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [orderCreateLoading, setOrderCreateLoading] = useState(false);
  const [order, setOrder] = useState<any>([]);
  const [fbaNo, setFbaNo] = useState<any>(null);
  const [shipmentCode, setShipmentCode] = useState<any>(null);
  const id = localStorage.getItem("id");

  // BEFORE
  // eslint-disable-next-line
  const [beforeTotalVolume, setBeforeTotalVolume] = useState(0);
  // eslint-disable-next-line
  const [beforeVolume, setBeforeVolume] = useState(0);
  // eslint-disable-next-line
  const [beforePercent, setBeforePercent] = useState(0);
  const [beforeSuccessPercent, setBeforeSuccessPercent] = useState(0);

  // AFTER
  // eslint-disable-next-line
  const [afterVolume, setAfterVolume] = useState(0);
  const [afterPercent, setAfterPercent] = useState(0);

  // eslint-disable-next-line
  const [storageVolume, setStorageVolume] = useState(0);
  // eslint-disable-next-line
  const [totalStorageVolume, setTotalStorageVolume] = useState(0);
  // eslint-disable-next-line
  const [totalStorage, setTotalStorage] = useState(0);
  // eslint-disable-next-line
  const [userVolume, setUserVolume] = useState(0);
  const [productVolume, setProductVolume] = useState(0);
  const [daysRemainingInMonth, setDaysRemainingInMonth] = useState(0);

  useEffect(() => {
    getTotalStorageVolume();
  }, []);

  const getDaysRemainingInMonth = (): number => {
    // Get the current date
    const now = dayjs();
    // Get the last day of the current month
    const endOfMonth = now.endOf("month");
    // Calculate the difference in days between now and the end of the month
    const difference = endOfMonth.diff(now, "day");

    // Return the difference (Adding 1 because it includes today)
    return difference;
  };

  const getDaysInCurrentMonth = (): number => {
    // Şu anki tarihi al
    const now = dayjs();
    // Bu ayın son gününü al ve ayın gününü döndür (ayın kaçıncı günü olduğunu verir, dolayısıyla bu ayın gün sayısıdır)
    const daysInMonth = now.endOf("month").date();

    return daysInMonth;
  };

  const externalToken = localStorage.getItem("externalAccessToken");
  const externalRefreshToken = localStorage.getItem("externalRefreshToken");

  useEffect(() => {
    let box = 100 * 100 * 100;
    let total = 0;
    //  eslint-disable-next-line
    orderItemList.map((item) => {
      let productItem: any = item;
      total +=
        calculateVolumeInCubic(
          productItem.length,
          productItem.width,
          productItem.height
        ) * productItem.qty;
    });
    let boxCount = total / box;
    setProductVolume(boxCount);
    // setTotalArea(boxCount);
    // setTotalPrice((boxCount * 14.99).toFixed(2));
  }, [orderItemList]);

  function calculateVolumeInCubic(
    depthInInches: number,
    widthInInches: number,
    heightInInches: number
  ): number {
    depthInInches = depthInInches * 2.54;
    widthInInches = widthInInches * 2.54;
    heightInInches = heightInInches * 2.54;
    return depthInInches * widthInInches * heightInInches;
  }

  const refreshPage = () => {
    window.location.reload();
  };

  const getIncomingInventoryDetail = (id: number) => {
    IncomingInventoryService.getIncomingInventoriesById(id)
      .then((response: any) => {
        const data: IIncomingInventoryResponse = new IIncomingInventoryResponse(response.data);
        setOrder([...order, data])
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const onCreateOrder = () => {
    setOrderCreateLoading(true);
    // let claimOrderItems: any = [];

    // orderItemList.map((orderItem: any) => {
    //   let orderItemObj = {
    //     productId: orderItem.id,
    //     qty: orderItem.qty,
    //     fnskuLabel: null,
    //     trackingCode: null,
    //     claimOrderItemGeneralServices: [],
    //   };

    //   orderItemObj.claimOrderItemGeneralServices = [];

    //   claimOrderItems.push(orderItemObj);
    // });

    // let postObj = {
    //   shippingFee: 0,
    //   note: null,
    //   shippingProvider: "AMAZON",
    //   warehouseId: 1,
    //   orderType: "STORAGE",
    //   isInWareHouse: false,
    //   claimOrderItems: claimOrderItems,
    //   claimOrderGeneralServices: [],
    // };

    // ClaimOrderService.create(postObj)
    //   .then((response: any) => {
    //     const claimOrderId = response.data;
    //     setFbaNo(claimOrderId);
    //     setShipmentCode(`ONESHIP (${id}-STORAGE-${claimOrderId})`);
    //     openNotification("success", "Success", "Warehouse shipment created");
    //     setOrderCreated(true);
    //     setOrderCreateLoading(true);
    //     getClaimOrderDetail(claimOrderId);
    //   })
    //   .catch((e: Error) => {
    //     console.log(e);
    //     setOrderCreateLoading(true);
    //   });
    let postObj = {
      userId: Number(id),
      asin: orderItemList[0]?.asin,
      quantity: Number((orderItemList[0] as any)?.qty),
    };

    IncomingInventoryService.createIncomingInventory(postObj)
      .then((response: any) => {
        const orderId = response.data.id;
        setFbaNo(orderId);
        setShipmentCode(`ONESHIP (${id}-STORAGE-${orderId})`);
        openNotification("success", "Success", "Warehouse shipment created");
        setOrderCreated(true);
        setOrderCreateLoading(true);
        getIncomingInventoryDetail(orderId);
      })
      .catch((e: Error) => {
        console.log(e);
        setOrderCreateLoading(true);
      });
  };

  const getBalance = () => {
    OneamzAppService.getBalance()
      .then((response: any) => {
        let currentBalance: number = response.data.balance;
        setCurrentBalance(currentBalance);
        if (currentBalance < totaPrice) {
          setLoadBalance((totaPrice - currentBalance).toFixed(2));
          setIsModalOpen(true);
        } else {
          setIsConfirmModal(true);
        }
      })
      .catch((e: Error) => {});
  };

  const handleSubmit = (values: any) => {
    setProductLoad(true);
    ProductService.getbyASIN(values.asin)
      .then((response: any) => {
        let data: IProductResponse = response.data;
        if (!orderItemList.some((item) => item.asin === data.asin)) {
          let newData = {
            ...data,
            qty: 1,
            fnskuLabel: null,
            trackingCode: null,
            services: [],
          };
          setOrderItemList([...orderItemList, newData]);
          message.success("Product added");
        } else {
          message.success("Product available in the list");
        }
        setProductLoad(false);
        asinFind.resetFields();
      })
      .catch((e: Error) => {
        setProductLoad(false);
        message.error("Product not found");
      });
  };

  const onHandleRemoveProduct = (id: any) => {
    const updatedList = orderItemList.filter((item: any) => item.id !== id);
    setOrderItemList(updatedList);
    message.success("Product extracted");
  };

  const onHandleProductChange = (updatedItem: any) => {
    setOrderItemList((orderItemList) => {
      return orderItemList.map((item: any) => {
        // Check if the current item's id matches the updated item's id
        if (item.id === updatedItem.id) {
          // If yes, return the updated item
          return updatedItem;
        }
        // Otherwise, return the item as is
        return item;
      });
    });
  };

  const getTotalStorageVolume = () => {
    // InventoryServiceV1.getTotalStorageVolume()
    //   .then((response: any) => {
    //     let data = response.data;
    //     setBeforeVolume(data);

    //     let total = Math.ceil(data);
    //     setBeforeTotalVolume(total);

    //     let percent = (data / total) * 100;
    //     setBeforePercent(percent);
    //     setBeforeSuccessPercent(percent);

    //     setUserVolume(data);
    //     setStorageVolume(percent);
    //     setTotalStorage(total);
    //     setTotalStorageVolume(total);
    //   })
    //   .catch((e: Error) => {});
  };

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const handleOk = () => {
  //   onCreateOrder();
  //   setIsModalVisible(false);
  // };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    let volume = userVolume + productVolume;
    let total = Math.ceil(volume);
    let percent = (volume / total) * 100;
    let diffBox = total - Math.ceil(userVolume);
    setTotalArea(diffBox);

    if (volume > beforeTotalVolume) {
      let diffVolume = beforeTotalVolume - volume;
      diffVolume = Math.abs(diffVolume);
      setAfterVolume(diffVolume);

      setBeforeSuccessPercent(100);

      if (diffBox > 0) {
        let diffPercent = (diffVolume / diffBox) * 100;
        setAfterPercent(diffPercent);
      } else {
        setAfterPercent(0);
      }
    } else {
      setAfterVolume(0);
      setAfterPercent(0);
      let diffPercent = (volume / beforeTotalVolume) * 100;
      setBeforeSuccessPercent(diffPercent);
    }

    let dayCount = getDaysInCurrentMonth();
    let diffDayCount = getDaysRemainingInMonth();
    setDaysRemainingInMonth(diffDayCount);
    let boxPrice = (14.99 / dayCount) * diffDayCount;

    setTotalPrice((diffBox * boxPrice).toFixed(2));
    setStorageVolume(percent);
    setTotalStorage(total);
    setTotalStorageVolume(total);
    // eslint-disable-next-line
  }, [productVolume]);

  return (
    <>
      {!orderCreated ? (
        <>
          <Modal
            title={<h3 style={{ margin: "0px" }}>Insufficient Credit</h3>}
            open={isModalOpen}
            onCancel={handleCancel}
            footer={() => (
              <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                <Col>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "black",
                      color: "pure-white",
                      padding: "0px 30px",
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <a
                    target="_blank" rel="noreferrer"
                    href={`${process.env.REACT_APP_EXTERNAL_BASE_URL}/public/module/callback?accessToken=${externalToken}&refreshToken=${externalRefreshToken}&redirectUrl=/balance?amount%3D${loadBalance}%26openPaymentModal%3Dtrue`}
                  >
                    <Button
                      style={{
                        backgroundColor: "#FFCB2B",
                        color: "black",
                        padding: "0px 20px",
                      }}
                      type="primary"
                    >
                      Upload Credit
                    </Button>
                  </a>
                </Col>
              </Row>
            )}
          >
            <Row justify={"center"}>
              <InfoCircleOutlined
                style={{
                  fontSize: "48px",
                  marginTop: "20px",
                  color: "#FFCB2B",
                }}
              />
            </Row>
            <h3
              style={{
                textAlign: "center",
                fontWeight: 400,
                margin: "40px 0px",
              }}
            >
              You must load at least <b>${loadBalance}</b> credit for your
              transaction
            </h3>
          </Modal>
          <Modal
            open={isConfirmModal}
            onCancel={() => {
              setIsConfirmModal(false);
            }}
            footer={() => (
              <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                <Col>
                  <Button
                    className="bg-slate-blue border-radius-8 btn-hover-white btn font-color-cloud btn btn-text"
                    onClick={() => {
                      setIsConfirmModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="bg-oneamz-yellow border-radius-8 btn-hover-dark font-color-slate-blue btn btn-text"
                    loading={orderCreateLoading}
                    onClick={() => {
                      onCreateOrder();
                    }}
                  >
                    Confirm
                  </Button>
                </Col>
              </Row>
            )}
          >
            <Row justify={"center"}>
              <WarningIcon
                className="ml-32"
                style={{
                  fontSize: "48px",
                  marginTop: "20px",
                  color: "#FFCB2B",
                }}
              />
            </Row>
            <Row justify={"center"}>
              <Col span={20}>
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: 400,
                    margin: "40px 0px 10px",
                  }}
                >
                  <b>Number of Storage Boxes to Add:</b> {totalArea}
                </h3>
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: 400,
                    margin: "40px 0px",
                  }}
                >
                  {totaPrice > 0 && (
                    <>
                      For storage service payment, the total amount of{" "}
                      <b>${(14.99 * totalArea).toFixed(2)}</b> will be deducted
                      from your total credits monthly, reducing by a daily
                      amount of <b>${totaPrice}</b> for{" "}
                      <b>{daysRemainingInMonth}</b> days. Do you confirm?
                    </>
                  )}
                  {totaPrice <= 0 && (
                    <>
                      There is space for products in your storage capacity, and
                      your credit will not be charged for the transaction this
                      month.
                    </>
                  )}
                </h3>
              </Col>
            </Row>
          </Modal>
          <Row>
            <Col span={24}>
              <ProgressBar currentStep={0}/>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Card className="border-radius-md border-slate-blue-02 mt-24">
                <h3 className="h-3 font-color-slate-blue mt-0">
                  Send Products to Warehouse with ASIN Code!
                </h3>
                <p className="text fs-14">
                  Our shipped products are stored quickly and in an organized
                  manner in accordance with quality standards. Our warehouse is
                  equipped with effective stock management so that customer
                  orders can be processed quickly and smoothly. After going
                  through a secure storage process, our products are quickly
                  made ready for shipment, which ensures timely transportation
                  to customers.
                </p>
                <Form form={asinFind} onFinish={handleSubmit}>
                  <Row className="mt-24">
                    <Col>
                      <Form.Item
                        className="mb-0"
                        name="asin"
                        rules={[
                          {
                            required: true,
                            message: "Enter ASIN code",
                          },
                        ]}
                      >
                        <Input
                          className="amz-input-44px"
                          placeholder="ASIN..."
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button
                        htmlType="submit"
                        loading={productLoad}
                        className="ml-24 btn btn-hover-dark bg-slate-blue font-color-cloud btn-text"
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Alert
                className="border-none border-radius-md mt-24"
                description={
                  <p className="text fs-14 m-0">
                    <b>
                      *Please use the following address information in your
                      order
                    </b>
                    <br />
                    <br />
                    <b>OneAmz Warehouse Address: </b> 356 GETTY AVE BLD-5A #
                    ONEAMZ-0413464 CLIFTON, NJ 07011-2118 United States <br />
                    <b>Phone: </b> 917 306 6033
                  </p>
                }
                type="info"
                showIcon
              />
            </Col>
          </Row>
          <Row className="mt-24">
            <Col span={24}>
              <Card className="bg-cloud border-radius-md">
                {/* <Row justify={"space-between"}>
                                    <p className='text font-color-slate-blue'>
                                        <b>{totalStorage} Box, %{(storageVolume > 0) && storageVolume.toFixed(2)} Dolu</b>
                                    </p>
                                </Row> */}
                <Row>
                  <Col span={24}>
                    <Card className="bg-white border-radius-md">
                      <Row gutter={[32, 32]}>
                        <Col md={16} sm={12} xs={12}>
                          <p
                            style={{ textAlign: "center" }}
                            className="font-color-slate-blue-06 fs-18"
                          >
                            Used{" "}
                            <b className="font-color-slate-blue">
                              {beforeTotalVolume}
                            </b>{" "}
                            Storage Box
                            {/* Kullanılan <b>{beforePercent}</b> Storage Box
                                                        Kullanılan <b>{storageVolume}</b> Storage Box */}
                          </p>
                          <Tooltip
                            placement="bottom"
                            title={<b>%{beforeSuccessPercent.toFixed(0)}</b>}
                          >
                            <Progress
                              className="storage-progress"
                              size={["100%", 20]}
                              percent={beforeSuccessPercent}
                              success={{ percent: beforePercent }}
                              showInfo={false}
                            />
                          </Tooltip>
                        </Col>
                        <Col md={8} sm={12} xs={12}>
                          <p
                            style={{ textAlign: "center" }}
                            className="font-color-slate-blue-06  fs-18"
                          >
                            Added{" "}
                            <b className="font-color-slate-blue">
                              {totalArea > 0 ? `+${totalArea}` : 0}
                            </b>{" "}
                            Storage Box
                            {/* {afterPercent} */}
                          </p>
                          <Tooltip
                            placement="bottom"
                            title={
                              <b className="font-color-slate-blue">
                                %{afterPercent.toFixed(0)}
                              </b>
                            }
                            color={"#FFCB2B"}
                          >
                            <Progress
                              className="product-storage-progress"
                              size={["100%", 20]}
                              percent={100}
                              success={{ percent: afterPercent }}
                              showInfo={false}
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                      <Row className="mt-16">
                        <Badge
                          color="#333E48"
                          text="Used Storage Box"
                          className="mr-24"
                        />
                        <Badge
                          color="#FFCB2B"
                          text="Space required for storage of new products"
                        />
                      </Row>
                    </Card>
                  </Col>
                  {/* {userVolume} - {productVolume} - {afterVolume} - {beforeSuccessPercent} */}
                </Row>
                <Row className="mt-2">
                  {orderItemList.map((item, key) => (
                    <Col span={24} key={key}>
                      <InventoryShipmentCard
                        changeProduct={onHandleProductChange}
                        removeProduct={onHandleRemoveProduct}
                        item={item}
                      />
                    </Col>
                  ))}
                  {orderItemList.length === 0 ? (
                    <Col span={24}>
                      <Empty
                        description={
                          <h3>Add product to warehouse shipping list</h3>
                        }
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row className="mt-24" gutter={24} justify={"end"}>
                  <Col className="text fs-12 font-color-slate-blue-05">
                    STORAGE BOX: <b>{totalArea}</b>
                  </Col>
                  <Col className="text fs-12 font-color-slate-blue-05">
                    <b>UNIT COST: $14.99</b>
                  </Col>
                  <Col className="text fs-12 font-color-slate-blue">
                    <b>TOTAL COST: ${totaPrice}</b>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="mt-24" justify={"space-between"}>
            <Col>
              <Button className="btn btn-hover-dark border-radius-12 bg-cloud btn-text font-color-slate-blue">
                Back
              </Button>
            </Col>
            <Col>
              <Button
                disabled={orderItemList.length <= 0}
                onClick={getBalance}
                className="btn btn-hover-white border-radius-12 bg-slate-blue btn-text font-color-cloud"
              >
                Send to Warehouse
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col span={24}>
              <StarterCard />
            </Col>
          </Row>
          <Card
            className="mt-24"
            bodyStyle={{ border: "none", padding: "0px" }}
            style={{ border: "none" }}
          >
            <Row gutter={24}>
              <Col xl={6} xs={24}>
                <Card className="bg-oneamz-yellow border-radius-md">
                  <p className="text font-color-slate-blue bg-cloud p-8 d-inline border-radius-sm">
                    <b>
                      WAREHOUSE SHIPPING CODE <InfoIcon className="wh-14" />
                    </b>
                  </p>
                  <h2 className="h-3 font-color-slate-blue mt-16 mb-0">
                    ONESHIP
                  </h2>
                  <h2 className="h-3 font-color-slate-blue mt-0">
                    ({id}-STORAGE-{fbaNo})
                  </h2>
                  <p className="text fs-18 font-color-slate-blue">
                    To access the details of your product, you can search within
                    the app with the oneship shipping code
                  </p>
                  <Row justify={"center"} className="mt-64">
                    <Col>
                      <Image preview={false} src={FbaImg}/>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl={18} xs={24}>
                <Card className="bg-cloud border-radius-md">
                  <h1 className="h-2 font-color-slate-blue m-0">
                    Congratulations, you have completed Step 1!
                  </h1>
                  <p className="text fs-24 font-color-slate-blue m-0">
                    Your shipment request has been successfully registered
                  </p>
                  <p className="text font-color-slate-blue mt-8">
                    Thank you for your care and attention. You have successfully
                    completed this step of your warehouse fulfillment process.
                    You can complete Step 2 and finalize your warehouse
                    fulfillment process.
                  </p>
                  <p className="pretitle font-color-slate-blue mt-32 upper-case">
                    PRODUCT INFORMATION
                  </p>
                  {order.map((item: any, index: any) => (
                    <StorageResultItemCard
                      item={item}
                      key={index}
                    />
                  ))}
                </Card>

                <Alert
                  className="border-none border-radius-md mt-24"
                  description={
                    <p className="text fs-14 m-0">
                      <b>IMPORTANT INFORMATION:</b> Please note that your order
                      will not be completed unless you send the products to the
                      address below.
                    </p>
                  }
                  type="info"
                  showIcon
                />

                <Card className="bg-cloud mt-24 border-radius-md">
                  <Row gutter={36}>
                    <Col span={24}>
                      <h2 className="h-2 m-0 font-color-slate-blue mb-0">
                        Step 2: Send Products to Warehouse
                      </h2>
                      <p className="font-color-slate-blue fs-14 btn-text-sm mt-8">
                        We expect you to send the products you have specified
                        above to the OneAmz Warehouse address below.
                      </p>
                    </Col>
                    <Col span={24}>
                      <WarehouseCard shipmentCode={shipmentCode} />
                    </Col>
                  </Row>
                  <Row justify={"end"} gutter={8}>
                    {/* <Col>
                      <Button
                        onClick={() => navigate(`/fba-order/detail/${fbaNo}`)}
                        className=" btn btn-text btn-hover-dark bg-cloud font-color-slate-blue border-slate-blue border-radius-12 mt-24"
                      >
                        Go to Shipment Detail
                      </Button>
                    </Col> */}
                    <Col>
                      <Button
                        onClick={() => refreshPage()}
                        className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-12 mt-24"
                      >
                        Pull New Product(s) to Warehouse
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Card>
        </>
      )}
    </>
  );
};

export default InventoryNewV2;
