import { IBoxResponse } from "./Box";
import { IProductResponse } from "./Product";
import { IUserResponse } from "./User";

interface InventoryStockLogResponse {
  id: number;
  user: IUserResponse;
  inventory: string;
  quantity: number;
  type: "ADD" | "REMOVE";
  createdAt: string;
}

interface InventoryResponse {
  id: number;
  user: IUserResponse;
  product: IProductResponse;
  box: IBoxResponse;
  stock: number;
  stockLogs: InventoryStockLogResponse[];
  detailUrl: string;
  orderCode: string;
  printUrl: string;
}

interface InventoryRequest {
  userId?: number;
  startDate?: string;
  endDate?: string;
  page: number;
  size: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";
}

export class IInventoryResponse implements InventoryResponse {
  id: number;
  user: IUserResponse;
  product: IProductResponse;
  box: IBoxResponse;
  stock: number;
  stockLogs: InventoryStockLogResponse[];
  detailUrl: string;
  orderCode: string;
  printUrl: string;

  constructor(obj: InventoryResponse) {
    this.id = obj.id;
    this.user = new IUserResponse(obj.user);
    this.product = new IProductResponse(obj.product);
    this.box = new IBoxResponse(obj.box);
    this.stock = obj.stock ?? 0;
    this.stockLogs = obj.stockLogs ?? [];
    this.detailUrl = `/storage-order/detail/${obj.id}`;
    this.orderCode = `ONESHIP-${obj?.user?.id}-STORAGE-${obj?.id}`;
    this.printUrl = `/storage/print/${obj.id}`;
  }
}

export class IInventoryRequest implements InventoryRequest {
  userId?: number;
  startDate?: string;
  endDate?: string;
  page: number;
  size: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";

  constructor(obj: InventoryRequest) {
    this.userId = obj.userId;
    this.startDate = obj.startDate;
    this.endDate = obj.endDate;
    this.page = obj.page;
    this.size = obj.size;
    this.search = obj.search ?? "";
    this.sort = obj.sort;
    this.direction = obj.direction;
  }
}

interface PaginatedInventoriesResponse {
  data: InventoryResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class IPaginatedInventoriesResponse
  implements PaginatedInventoriesResponse
{
  data: IInventoryResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;

  constructor(obj: PaginatedInventoriesResponse) {
    this.data = obj.data.map((order) => new IInventoryResponse(order));
    this.page = obj.page;
    this.size = obj.size;
    this.totalElements = obj.totalElements;
    this.totalPages = obj.totalPages;
  }
}
