import { notification } from "antd";
import dayjs from "dayjs";
import OneamzAppService from "./services/OneamzAppService";
import moment from "moment";
import AuthService from "./services/AuthService";
import UserService from "./services/UserService";

type NotificationType = "success" | "info" | "warning" | "error";
export const openNotification = (
  type: NotificationType,
  status: string,
  description: string
) => {
  const args = {
    message: status,
    description: description,
    duration: 2,
  };
  notification[type](args);
};

export function formatDateTime(date: string): string {
  return date === null ? "" : dayjs(date).format("DD/MM/YYYY HH:mm");
}

export function getWarehouseCountryList() {
  return [
    { label: "Canada", value: "CA" },
    { label: "Mexico", value: "MX" },
    { label: "US", value: "US" },
    { label: "Czech Republic", value: "CZ" },
    { label: "France", value: "FR" },
    { label: "Germany", value: "DE" },
    { label: "Italy", value: "IT" },
    { label: "Poland", value: "PL" },
    { label: "Spain", value: "ES" },
    { label: "UK", value: "GB" },
    { label: "India", value: "IN" },
    { label: "Japan", value: "JP" },
    { label: "Singapore", value: "SG" },
    { label: "UAE", value: "AE" },
    { label: "Australia", value: "AU" },
  ];
}

export function getCountryByCode(code: string) {
  const countryList: any = [];
  countryList["CA"] = "Canada";
  countryList["MX"] = "Mexico";
  countryList["US"] = "US";
  countryList["CZ"] = "Czech Republic";
  countryList["FR"] = "France";
  countryList["CA"] = "Canada";
  countryList["DE"] = "Germany";
  countryList["IT"] = "Italy";
  countryList["PL"] = "Poland";
  countryList["ES"] = "Spain";
  countryList["CA"] = "Canada";
  countryList["GB"] = "UK";
  countryList["IN"] = "India";
  countryList["JP"] = "Japan";
  countryList["SG"] = "Singapore";
  countryList["AE"] = "UAE";
  countryList["AU"] = "Australia";

  return countryList[code];
}

export function formatMoney(balance: any): any {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(balance ?? 0);
}

export function convertDateFormat(dateStr: any): string {
  return dayjs(dateStr, "DD/MM/YYYY").format("YYYY-MM-DD");
}

export function formatDate(date: string): string {
  return dayjs(date).format("YYYY-MM-DD");
}

export function getDateRangeDefault(): any {
  let dateRangeDefaultValue: [dayjs.Dayjs, dayjs.Dayjs] = [
    dayjs().add(-90, "d"),
    dayjs(),
  ];
  return dateRangeDefaultValue;
}

export function refrestToken() {
  const currentTime = moment();
  const lastSavedTime = localStorage.getItem("lastCheckTime");
  const lastSavedMoment = lastSavedTime ? moment(lastSavedTime) : null;

  if (
    (lastSavedMoment && currentTime.diff(lastSavedMoment, "hours") >= 1) ||
    lastSavedTime === null
  ) {
    console.log(
      `It's been an hour since the last recorded time: ${lastSavedTime}`
    );

    let data = {
      refreshToken: localStorage.getItem("externalRefreshToken"),
    };

    OneamzAppService.refrestToken(data)
      .then((response: any) => {
        if (response.data.code === 401) {
          clearStorage();
          window.location.href = "/login";
        } else {
          localStorage.setItem(
            "lastCheckTime",
            currentTime.format("YYYY-MM-DD HH:mm:ss")
          );

          let data = response.data;
          let externalAccessToken = data.accessToken;
          let externalRefreshToken = data.refreshToken;
          localStorage.setItem("externalAccessToken", externalAccessToken);
          localStorage.setItem("externalRefreshToken", externalRefreshToken);

          getToken(externalAccessToken);
        }
      })
      .catch((e: Error) => {
        clearStorage();
        window.location.href = "/login";
      });
  }
}

export function getCountries() {
  return [
    // { label: "Afghanistan", value: "AF" },
    // { label: "Albania", value: "AL" },
    // { label: "Algeria", value: "DZ" },
    // { label: "Andorra", value: "AD" },
    // { label: "Angola", value: "AO" },
    // { label: "Antigua and Barbuda", value: "AG" },
    // { label: "Argentina", value: "AR" },
    // { label: "Armenia", value: "AM" },
    { label: "Australia", value: "AU" },
    // { label: "Austria", value: "AT" },
    // { label: "Azerbaijan", value: "AZ" },
    // { label: "Bahamas", value: "BS" },
    // { label: "Bahrain", value: "BH" },
    // { label: "Bangladesh", value: "BD" },
    // { label: "Barbados", value: "BB" },
    // { label: "Belarus", value: "BY" },
    { label: "Belgium", value: "BE" },
    // { label: "Belize", value: "BZ" },
    // { label: "Benin", value: "BJ" },
    // { label: "Bhutan", value: "BT" },
    // { label: "Bolivia", value: "BO" },
    // { label: "Bosnia and Herzegovina", value: "BA" },
    // { label: "Botswana", value: "BW" },
    { label: "Brazil", value: "BR" },
    // { label: "Brunei", value: "BN" },
    // { label: "Bulgaria", value: "BG" },
    // { label: "Burkina Faso", value: "BF" },
    // { label: "Burundi", value: "BI" },
    // { label: "Cabo Verde", value: "CV" },
    // { label: "Cambodia", value: "KH" },
    // { label: "Cameroon", value: "CM" },
    { label: "Canada", value: "CA" },
    // { label: "Central African Republic", value: "CF" },
    // { label: "Chad", value: "TD" },
    // { label: "Chile", value: "CL" },
    // { label: "China", value: "CN" },
    // { label: "Colombia", value: "CO" },
    // { label: "Comoros", value: "KM" },
    // { label: "Congo (Congo-Brazzaville)", value: "CG" },
    // { label: "Costa Rica", value: "CR" },
    // { label: "Croatia", value: "HR" },
    // { label: "Cuba", value: "CU" },
    // { label: "Cyprus", value: "CY" },
    // { label: "Czechia (Czech Republic)", value: "CZ" },
    // { label: "Democratic Republic of the Congo", value: "CD" },
    // { label: "Denmark", value: "DK" },
    // { label: "Djibouti", value: "DJ" },
    // { label: "Dominica", value: "DM" },
    // { label: "Dominican Republic", value: "DO" },
    // { label: "Ecuador", value: "EC" },
    { label: "Egypt", value: "EG" },
    // { label: "El Salvador", value: "SV" },
    // { label: "Equatorial Guinea", value: "GQ" },
    // { label: "Eritrea", value: "ER" },
    // { label: "Estonia", value: "EE" },
    // { label: "Eswatini (fmr. Swaziland)", value: "SZ" },
    // { label: "Ethiopia", value: "ET" },
    // { label: "Fiji", value: "FJ" },
    // { label: "Finland", value: "FI" },
    { label: "France", value: "FR" },
    // { label: "Gabon", value: "GA" },
    // { label: "Gambia", value: "GM" },
    // { label: "Georgia", value: "GE" },
    { label: "Germany", value: "DE" },
    // { label: "Ghana", value: "GH" },
    // { label: "Greece", value: "GR" },
    // { label: "Grenada", value: "GD" },
    // { label: "Guatemala", value: "GT" },
    // { label: "Guinea", value: "GN" },
    // { label: "Guinea-Bissau", value: "GW" },
    // { label: "Guyana", value: "GY" },
    // { label: "Haiti", value: "HT" },
    // { label: "Honduras", value: "HN" },
    // { label: "Hungary", value: "HU" },
    // { label: "Iceland", value: "IS" },
    { label: "India", value: "IN" },
    // { label: "Indonesia", value: "ID" },
    // { label: "Iran", value: "IR" },
    // { label: "Iraq", value: "IQ" },
    // { label: "Ireland", value: "IE" },
    // { label: "Israel", value: "IL" },
    { label: "Italy", value: "IT" },
    // { label: "Jamaica", value: "JM" },
    { label: "Japan", value: "JP" },
    // { label: "Jordan", value: "JO" },
    // { label: "Kazakhstan", value: "KZ" },
    // { label: "Kenya", value: "KE" },
    // { label: "Kiribati", value: "KI" },
    // { label: "Kuwait", value: "KW" },
    // { label: "Kyrgyzstan", value: "KG" },
    // { label: "Laos", value: "LA" },
    // { label: "Latvia", value: "LV" },
    // { label: "Lebanon", value: "LB" },
    // { label: "Lesotho", value: "LS" },
    // { label: "Liberia", value: "LR" },
    // { label: "Libya", value: "LY" },
    // { label: "Liechtenstein", value: "LI" },
    // { label: "Lithuania", value: "LT" },
    // { label: "Luxembourg", value: "LU" },
    // { label: "Madagascar", value: "MG" },
    // { label: "Malawi", value: "MW" },
    // { label: "Malaysia", value: "MY" },
    // { label: "Maldives", value: "MV" },
    // { label: "Mali", value: "ML" },
    // { label: "Malta", value: "MT" },
    // { label: "Marshall Islands", value: "MH" },
    // { label: "Mauritania", value: "MR" },
    // { label: "Mauritius", value: "MU" },
    { label: "Mexico", value: "MX" },
    // { label: "Micronesia", value: "FM" },
    // { label: "Moldova", value: "MD" },
    // { label: "Monaco", value: "MC" },
    // { label: "Mongolia", value: "MN" },
    // { label: "Montenegro", value: "ME" },
    // { label: "Morocco", value: "MA" },
    // { label: "Mozambique", value: "MZ" },
    // { label: "Myanmar", value: "MM" },
    // { label: "Namibia", value: "NA" },
    // { label: "Nauru", value: "NR" },
    // { label: "Nepal", value: "NP" },
    { label: "Netherlands", value: "NL" },
    // { label: "New Zealand", value: "NZ" },
    // { label: "Nicaragua", value: "NI" },
    // { label: "Niger", value: "NE" },
    // { label: "Nigeria", value: "NG" },
    // { label: "North Korea", value: "KP" },
    // { label: "North Macedonia", value: "MK" },
    // { label: "Norway", value: "NO" },
    // { label: "Oman", value: "OM" },
    // { label: "Pakistan", value: "PK" },
    // { label: "Palau", value: "PW" },
    // { label: "Palestine", value: "PS" },
    // { label: "Panama", value: "PA" },
    // { label: "Papua New Guinea", value: "PG" },
    // { label: "Paraguay", value: "PY" },
    // { label: "Peru", value: "PE" },
    // { label: "Philippines", value: "PH" },
    { label: "Poland", value: "PL" },
    // { label: "Portugal", value: "PT" },
    // { label: "Qatar", value: "QA" },
    // { label: "Romania", value: "RO" },
    // { label: "Russia", value: "RU" },
    // { label: "Rwanda", value: "RW" },
    // { label: "Saint Kitts and Nevis", value: "KN" },
    // { label: "Saint Lucia", value: "LC" },
    // { label: "Saint Vincent and the Grenadines", value: "VC" },
    // { label: "Samoa", value: "WS" },
    // { label: "San Marino", value: "SM" },
    // { label: "Sao Tome and Principe", value: "ST" },
    { label: "Saudi Arabia", value: "SA" },
    // { label: "Senegal", value: "SN" },
    // { label: "Serbia", value: "RS" },
    // { label: "Seychelles", value: "SC" },
    // { label: "Sierra Leone", value: "SL" },
    { label: "Singapore", value: "SG" },
    // { label: "Slovakia", value: "SK" },
    // { label: "Slovenia", value: "SI" },
    // { label: "Solomon Islands", value: "SB" },
    // { label: "Somalia", value: "SO" },
    // { label: "South Africa", value: "ZA" },
    // { label: "South Korea", value: "KR" },
    // { label: "South Sudan", value: "SS" },
    { label: "Spain", value: "ES" },
    // { label: "Sri Lanka", value: "LK" },
    // { label: "Sudan", value: "SD" },
    // { label: "Surilabel", value: "SR" },
    { label: "Sweden", value: "SE" },
    // { label: "Switzerland", value: "CH" },
    // { label: "Syria", value: "SY" },
    // { label: "Taiwan", value: "TW" },
    // { label: "Tajikistan", value: "TJ" },
    // { label: "Tanzania", value: "TZ" },
    // { label: "Thailand", value: "TH" },
    // { label: "Togo", value: "TG" },
    // { label: "Tonga", value: "TO" },
    // { label: "Trinidad and Tobago", value: "TT" },
    // { label: "Tunisia", value: "TN" },
    { label: "Türkiye", value: "TR" },
    // { label: "Turkmenistan", value: "TM" },
    // { label: "Tuvalu", value: "TV" },
    // { label: "Uganda", value: "UG" },
    // { label: "Ukraine", value: "UA" },
    { label: "United Arab Emirates", value: "AE" },
    { label: "United Kingdom", value: "GB" },
    { label: "USA", value: "US" },
    // { label: "Uruguay", value: "UY" },
    // { label: "Uzbekistan", value: "UZ" },
    // { label: "Vanuatu", value: "VU" },
    // { label: "Vatican City", value: "VA" },
    // { label: "Venezuela", value: "VE" },
    // { label: "Vietnam", value: "VN" },
    // { label: "Yemen", value: "YE" },
    // { label: "Zambia", value: "ZM" },
    // { label: "Zimbabwe", value: "ZW" }
  ];
}

export function getToken(externalToken: string) {
  let data = {
    externalToken: externalToken,
  };

  AuthService.authToken(data)
    .then((response: any) => {
      let accessToken = response.data.accessToken;
      let refreshToken = response.data.refreshToken;
      let accessTokenExpiredAt = response.data.accessTokenExpiredAt;
      let refreshTokenExpiredAt = response.data.refreshTokenExpiredAt;

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("accessTokenExpiredAt", accessTokenExpiredAt);
      localStorage.setItem("refreshTokenExpiredAt", refreshTokenExpiredAt);

      UserService.getMe().then((response: any) => {
        const data = response.data;
        localStorage.setItem("firstName", data.firstName);
        localStorage.setItem("lastName", data.lastName);
        localStorage.setItem("id", data.id);
        localStorage.setItem("email", data.username);
      });
    })
    .catch((e: Error) => {});
}

export function clearStorage() {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("accessTokenExpiredAt");
  localStorage.removeItem("refreshTokenExpiredAt");
  localStorage.removeItem("externalAccessToken");
  localStorage.removeItem("externalRefreshToken");
  localStorage.removeItem("lastCheckTime");

  localStorage.removeItem("firstName");
  localStorage.removeItem("lastName");
  localStorage.removeItem("id");
  localStorage.removeItem("email");
}

class Product {
  key: number;
  color: string;
  asin: string;
  width: number;
  height: number;
  depth: number;
  weight: number;
  quantity: number;

  constructor(
    key: number,
    color: string,
    asin: string,
    width: number,
    height: number,
    depth: number,
    weight: number,
    quantity: number
  ) {
    this.key = key;
    this.color = color;
    this.asin = asin;
    this.width = width;
    this.height = height;
    this.depth = depth;
    this.weight = weight;
    this.quantity = quantity;
  }
}

class Box {
  asin: string | null;
  products: Product[];
  maxWidth: number;
  maxHeight: number;
  maxDepth: number;
  maxWeight: number;

  constructor() {
    this.asin = null;
    this.products = [];
    this.maxWidth = 24; // inches
    this.maxHeight = 24; // inches
    this.maxDepth = 24; // inches
    this.maxWeight = 45; // lbs
  }

  getTotalWeight(): string {
    const totalWeight = this.products.reduce(
      (sum, p) => sum + p.weight * p.quantity,
      0
    );
    return totalWeight.toFixed(2);
  }

  getDesi(): string {
    const divisionNumber = 5000;
    const totalDesi = this.products.reduce((sum, p) => {
      const volumeInInches = p.width * p.height * p.depth;
      const volumeInCm = volumeInInches * 16.387;
      return sum + (volumeInCm / divisionNumber) * p.quantity;
    }, 0);
    return totalDesi.toFixed(2);
  }

  canAddProduct(product: Product): boolean {
    if (this.asin !== null && product.asin !== this.asin) {
      return false;
    }

    let currentWeight = this.products.reduce(
      (sum, p) => sum + p.weight * p.quantity,
      0
    );
    if (currentWeight + product.weight * product.quantity > this.maxWeight) {
      return false;
    }

    let usedVolume = this.products.reduce(
      (sum, p) => sum + p.width * p.height * p.depth * p.quantity,
      0
    );
    let newProductVolume =
      product.width * product.height * product.depth * product.quantity;
    let maxVolume = this.maxWidth * this.maxHeight * this.maxDepth;

    return usedVolume + newProductVolume <= maxVolume;
  }

  addProduct(product: Product): boolean {
    if (this.canAddProduct(product)) {
      if (this.asin === null) {
        this.asin = product.asin;
      }
      this.products.push(product);
      return true;
    }
    return false;
  }
}

export function handlePacking(productList: any[]): Box[] {
  let products: Product[] = [];
  productList.forEach((orderItem, index) => {
    products.push(
      new Product(
        index + 1,
        orderItem?.color,
        orderItem?.asin,
        orderItem?.width,
        orderItem?.height,
        orderItem?.length,
        orderItem?.weight,
        orderItem?.qty
      )
    );
  });

  let tempBoxes: Box[] = [];
  products.forEach((product) => {
    for (let i = 0; i < product.quantity; i++) {
      let added = false;
      for (let box of tempBoxes) {
        if (
          box.addProduct(
            new Product(
              product.key,
              product.color,
              product.asin,
              product.width,
              product.height,
              product.depth,
              product.weight,
              1
            )
          )
        ) {
          added = true;
          break;
        }
      }
      if (!added) {
        let newBox = new Box();
        newBox.addProduct(
          new Product(
            product.key,
            product.color,
            product.asin,
            product.width,
            product.height,
            product.depth,
            product.weight,
            1
          )
        );
        tempBoxes.push(newBox);
      }
    }
  });

  return tempBoxes;
}
