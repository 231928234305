import { Button, Card, Col, Row, Form, Drawer, Input } from "antd";
import { useEffect, useState } from "react";
import TicketCard from "./TicketCard";
import TicketService from "../../../services/TicketService";
import { useNavigate } from "react-router-dom";

const { Item } = Form;

const TicketList = () => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    getTickets();
  }, []);

  const getTickets = () => {
    setLoading(true);
    TicketService.getAll()
      .then((response: any) => {
        const data = response.data;
        setTickets(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  let role = localStorage.getItem("userRole");

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [open]);

  const update = (values: any) => {
    let postObj = {
      title: values.title,
      description: values.description,
    };

    TicketService.create(postObj)
      .then((response: any) => {
        getTickets();
        onClose();
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <>
      <Card loading={loading} className="bg-cloud border-radius-md">
        <Row justify={"space-between"} gutter={24}>
          <Col>
            {role == "CUSTOMER" ? (
              <Button
                onClick={showDrawer}
                className="btn-text btn-hover-white font-color-pure-white btn border-radius-sm bg-slate-blue"
              >
                Create New Request
              </Button>
            ) : (
              <></>
            )}
          </Col>
          <Col>
            <Button
              onClick={() => navigate(-1)}
              className="btn-text btn-hover-dark font-color-slate-blue bg-pure-white btn border-radius-sm"
            >
              Back
            </Button>
          </Col>
        </Row>
        <Row>
          {tickets.map((ticket) => (
            <TicketCard getTrigger={getTickets} ticketInfo={ticket} />
          ))}
        </Row>
      </Card>
      <Drawer
        title="Create Support Request"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Form form={form} onFinish={update} layout="vertical">
          <Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: "Title name required",
              },
            ]}
          >
            <Input />
          </Item>
          <Item name="description" label="Description">
            <Input />
          </Item>
          <Item>
            <Row justify={"end"}>
              <Button
                className="btn btn-hover-dark bg-cloud font-color-onyx btn-text mr-16"
                onClick={onClose}
              >
                Give up
              </Button>
              <Button
                className="btn btn-hover-white bg-slate-blue font-color-cloud btn-text"
                htmlType="submit"
              >
                Save
              </Button>
            </Row>
          </Item>
        </Form>
      </Drawer>
    </>
  );
};

export default TicketList;
