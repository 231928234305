import { Button, Card, Col, Row, Image, Modal } from "antd";
import { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./FBMCreate.scss";
import FBMCreateStep1 from "../components/FBMCreate/FBMCreateStep1";
import { openNotification } from "../utils";
import FBMCreateStep2 from "../components/FBMCreate/FBMCreateStep2";
import OneamzAppService from "../services/OneamzAppService";
import FBMProgressCard from "../components/V2/CreateFBMV2/FBMProgressCard";
import { ReactComponent as WarningIcon } from "../assets/icons/warning-icon.svg";
import StarterCard from "../components/V2/ProfileV2/StarterCard";
import { ReactComponent as InfoIcon } from "../assets/info-icon.svg";
import FbmImg from "../assets/dashboard-v2/fbm-quickaccess.png";
import FBAResultItemCard from "./V2/OrderDetail/FBAResultItemCard";
import { useNavigate } from "react-router-dom";
import AdditionalServiceService from "../services/AdditionalServiceService";
import { IFbmOrderResponse } from "../types/FbmOrder";
import FbmOrderService from "../services/FbmOrderService";
import WarehousesService from "../services/WarehousesService";

function FBMCreate() {
  const [current, setCurrent] = useState(0);
  const [totalQty, setTotalQty] = useState<any>(0);
  // eslint-disable-next-line
  const [totalWeight, setTotalWeight] = useState<any>(0);
  // eslint-disable-next-line
  const [totalDeci, setCurrentDeci] = useState<any>(0);
  // eslint-disable-next-line
  const [totalFBMCost, setTotalFBMCost] = useState<any>(0);
  const [totalFulfillmentCost, setTotalFulfillmentCost] = useState<any>(0);
  // eslint-disable-next-line
  const [totalServiceCost, setTotalServiceCost] = useState<any>(0);
  // eslint-disable-next-line
  const [FBMPrepServiceFee, setFBMPrepServiceFee] = useState<any>(0);
  // eslint-disable-next-line
  const [totalCustomServiceCost, setTotalCustomServiceCost] = useState<any>(0);
  // eslint-disable-next-line
  const [totalFulfillmentCostPerUnit, setTotalFulfillmentCostPerUnit] =
    useState<any>(0);
  const [productList, setProductList] = useState<any>([]);
  const [loadBalance, setLoadBalance] = useState<any>(0);
  // eslint-disable-next-line
  const [warehouseList, setWarehouseList] = useState<any>([]);
  const [orderCreateLoading, setOrderCreateLoading] = useState(false);
  // eslint-disable-next-line
  const [shipmentFee, setShipmentFee] = useState(0);
  // eslint-disable-next-line
  const [currentBalance, setCurrentBalance] = useState(0);
  const [fbmCreated, setFbmCreated] = useState<any>(false);
  const [fbmNo, setFbmNo] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  // eslint-disable-next-line
  const [note, setNote] = useState<any>(null);
  const id = localStorage.getItem("id");
  const externalToken = localStorage.getItem("externalAccessToken");
  const externalRefreshToken = localStorage.getItem("externalRefreshToken");
  const [orderItemServices, setOrderItemServices] = useState<any>([]);
  const [orderServices, setOrderServices] = useState<any>([]);
  const [order, setOrder] = useState<any>([]);
  const [receiver, setReceiver] = useState<any>(null);
  const navigate = useNavigate();

  const getAllWarehouse = () => {
    const request: any = ``;
    WarehousesService.getWarehouses(request)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return {
            value: item?.id,
            label: `${item?.code} ${item?.address}`,
            country: item?.countryCode,
            code: item?.code,
          };
        });
        setWarehouseList(newArray);
      })
      .catch((e: Error) => {});
  };

  const getOrderServices = () => {
    const request: any = ``;
    AdditionalServiceService.getAdditionalServices(request)
      .then((response: any) => {
        const filteredList = response.data.data.filter(
          (service: any) => service.type === "FBM"
        );
        setOrderServices(filteredList);

        const orderServiceUpdated = filteredList.map((service: any) => ({
          ...service,
          qty: 0,
        }));

        setOrderServices(orderServiceUpdated);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getOrderItemServices = () => {
    const request: any = ``;
    AdditionalServiceService.getAdditionalServices(request)
      .then((response: any) => {
        let items = response.data.data.filter(
          (service: any) => service.type === "FBM"
        );
        items = items.sort((a: any, b: any) => a.id - b.id);
        const orderItemServiceUpdated = items.map((service: any) => ({
          ...service,
          qty: service.id === 1 ? 1 : service.required === false ? 0 : 1,
        }));
        setOrderItemServices(orderItemServiceUpdated);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getOrderItemServices();
    getOrderServices();
    getAllWarehouse();
    // eslint-disable-next-line
  }, []);

  const calcParams = () => {
    let deci = 0;
    let weight = 0;
    let qty = 0;

    let fulfillmentCost: any = 0;
    let serviceCost = 0;
    let customServiceCost = 0;
    let fulfillmentCostPerUnit = 0;
    let totalShipmentFee = 0;

    let FBMCost = 0;
    // eslint-disable-next-line
    orderServices.map((orderService: any) => {
      FBMCost += orderService.qty * orderService.price;
    });

    setTotalFBMCost(FBMCost);
    // eslint-disable-next-line
    productList.map((orderItem: any) => {
      weight += orderItem.qty * orderItem.weight;
      deci += orderItem.qty * orderItem.deci;
      qty += orderItem.qty;
      // eslint-disable-next-line
      orderItem.services.map((serviceItem: any) => {
        if (serviceItem.required === false) {
          if (serviceItem.qty > 0) {
            customServiceCost += serviceItem.qty * serviceItem.price;
          }
        } else {
          serviceCost += serviceItem.qty * serviceItem.price;
        }
      });

      totalShipmentFee += orderItem?.shipmentFee ?? 0;
    });

    fulfillmentCost = (
      FBMCost +
      shipmentFee +
      serviceCost +
      customServiceCost
    ).toFixed(2);

    const FbmPrepFee = (
      FBMCost +
      serviceCost +
      customServiceCost +
      totalShipmentFee
    ).toFixed(2);

    fulfillmentCostPerUnit = fulfillmentCost / qty;

    setTotalQty(qty);
    setTotalWeight(weight.toFixed(2));
    setCurrentDeci(deci.toFixed(2));
    setTotalFulfillmentCost(FbmPrepFee);
    setTotalCustomServiceCost(customServiceCost.toFixed(2));
    setTotalServiceCost(serviceCost.toFixed(2));
    setFBMPrepServiceFee(FbmPrepFee);
    setTotalFulfillmentCostPerUnit(fulfillmentCostPerUnit.toFixed(2));
  };

  useEffect(() => {
    calcParams();
    // eslint-disable-next-line
  }, [productList, orderServices]);

  const addProduct = (data: any) => {
    if (!productList.some((item: any) => item.asin === data.asin)) {
      let newData = {
        ...data,
        qty: 1,
        purchasePrice: 0,
        fnskuLabel: null,
        trackingCode: null,
        services: JSON.parse(JSON.stringify(orderItemServices)),
        customer: {},
        shipmentCompany: "ONEAMZ",
        shipmentFee: null,
        deliveryOption: null,
      };
      setProductList([...productList, newData]);
      openNotification("success", "Success", "Product added to the list");
    } else {
      openNotification("warning", "Warning", "Product available in the list");
    }
  };

  const onHandleSetQty = (id: any, qty: any) => {
    if (qty === null) {
      return false;
    }
    let products = productList.map((product: any) => {
      if (product.id === id) {
        product.qty = qty;

        let services = product.services.map((serviceItem: any) => {
          if (serviceItem.required === true) {
            serviceItem.qty = qty;
            return serviceItem;
          } else {
            if (serviceItem.id === 1) {
              if (serviceItem.qty > 0) {
                serviceItem.qty = qty;
                return serviceItem;
              }
            } else {
              if (serviceItem.qty > qty) {
                serviceItem.qty = qty;
                return serviceItem;
              }
            }
          }
          return serviceItem;
        });

        product.services = services;
        return product;
      }
      return product;
    });
    setProductList(products);
  };

  const onHandleSetServiceQty = (productId: any, serviceId: any, qty: any) => {
    let products = productList.map((product: any) => {
      if (product.id === productId) {
        let services = product.services.map((serviceItem: any) => {
          if (serviceItem.required === true) {
            return serviceItem;
          } else {
            if (serviceItem.id === serviceId) {
              serviceItem.qty = qty;
              return serviceItem;
            }
          }
          return serviceItem;
        });

        product.services = services;
        return product;
      }
      return product;
    });
    setProductList(products);
  };

  const onHandleRemoveProduct = (id: any) => {
    const updatedList = productList.filter((item: any) => item.id !== id);
    setProductList(updatedList);
    openNotification("success", "Success", "Product removed from the list");
  };

  const onHandleChangeShipmentFee = (id: any, fee: any) => {
    let products = productList.map((product: any) => {
      if (product.id === id) {
        product.shipmentFee = fee;

        return product;
      }
      return product;
    });
    setProductList(products);
  };

  const onHandleChangeDeliveryOption = (id: any, deliveryOption: any) => {
    let products = productList.map((product: any) => {
      if (product.id === id) {
        product.deliveryOption = deliveryOption;

        return product;
      }
      return product;
    });
    setProductList(products);
  };

  const onHandleChangeCustomer = (id: any, data: any) => {
    let products = productList.map((product: any) => {
      if (product.id === id) {
        product.customer = data;
        return product;
      }
      return product;
    });
    setProductList(products);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const steps = [
    {
      title: "",
      content: (
        <FBMCreateStep1
          addProduct={addProduct}
          removeProduct={onHandleRemoveProduct}
          setQty={onHandleSetQty}
          setServiceQty={onHandleSetServiceQty}
          productList={productList}
          totalQty={totalQty}
        />
      ),
    },
    {
      title: "",
      content: (
        <FBMCreateStep2
          changeCustomer={onHandleChangeCustomer}
          changeShipmentFee={onHandleChangeShipmentFee}
          changeDeliveryOption={onHandleChangeDeliveryOption}
          productList={productList}
        />
      ),
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const getBalance = () => {
    for (let i = 0; i < productList.length; i++) {
      const product = productList[i];
      if (product.shipmentFee === null) {
        openNotification("warning", "Warning", "Select shipping offer");
        return false;
      }
    }

    let isUndefined = false;
    for (let i = 0; i < productList.length; i++) {
      const product = productList[i];
      for (let key in product.customer) {
        if (key !== "addressLine2") {
          if (
            product.customer[key] === undefined ||
            product.customer[key] === "" ||
            product.customer[key] === null
          ) {
            isUndefined = true;
            break;
          }
        }
      }
    }

    if (isUndefined) {
      openNotification(
        "warning",
        "Warning",
        "Enter required fields in customer information"
      );
      return false;
    }

    OneamzAppService.getBalance()
      .then((response: any) => {
        let currentBalance: number = response.data.balance;
        setCurrentBalance(currentBalance);
        if (currentBalance < totalFulfillmentCost) {
          setLoadBalance((totalFulfillmentCost - currentBalance).toFixed(2));
          setIsModalOpen(true);
        } else {
          setIsConfirmModal(true);
        }
      })
      .catch((e: Error) => {});
  };

  const onCreateOrder = () => {
    setOrderCreateLoading(true);
    let orderItems: any = [];
    // eslint-disable-next-line
    productList.map((orderItem: any) => {
      let orderItemObj = {
        asin: orderItem?.asin,
        quantity: orderItem?.qty,
        price: Number(totalFulfillmentCost),
        additionalServices: [],
      };

      if (orderItem.qty > 0) {
        let orderItemServiceListArr: any = [];
        // eslint-disable-next-line
        orderItem.services.map((service: any) => {
          if (service.qty > 0) {
            const orderItemService = {
              id: service.id,
              quantity: service.qty,
            };
            orderItemServiceListArr.push(orderItemService);
          }
        });

        orderItemObj.additionalServices = orderItemServiceListArr;
        orderItems.push(orderItemObj);
      }
    });

    const customer = productList[0].customer;

    let postObj = {
      userId: Number(id),
      notes: note,
      customerName: customer.fullName,
      customerCountryCode: customer.country,
      customerAddress1: customer.addressLine1,
      customerAddress2: customer.addressLine2,
      customerState: customer.state,
      customerCity: customer.city,
      customerZipCode: customer.postCode,
      customerPhone: customer.phoneNumber,
      customerEmail: customer.email,
      items: orderItems,
    };

    FbmOrderService.createFbmOrder(postObj)
      .then((response: any) => {
        const orderId = response.data.id;
        openNotification("success", "Success", "FBM shipment created");
        setFbmNo(orderId);
        setFbmCreated(true);
        getFbmOrderDetail(orderId);
        setOrderCreateLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setOrderCreateLoading(false);
      });
  };

  const getFbmOrderDetail = (id: number) => {
    FbmOrderService.getFbmOrderById(id)
      .then((response: any) => {
        const data: IFbmOrderResponse = new IFbmOrderResponse(response.data);
        setOrder([...order, data]);
        getReceiver(id);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getReceiver = (id: any) => {
    const request: any = `?userId=${id}`;
    FbmOrderService.getFbmOrders(request)
      .then((response: any) => {
        const data = response.data.data;
        if (data.length > 0) {
          setReceiver(data[data.length - 1]);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <>
      {!fbmCreated ? (
        <>
          <Modal
            title={<h3 style={{ margin: "0px" }}>Insufficient Credit</h3>}
            open={isModalOpen}
            onCancel={handleCancel}
            footer={() => (
              <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                <Col>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "black",
                      color: "pure-white",
                      padding: "0px 30px",
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_EXTERNAL_BASE_URL}/public/module/callback?accessToken=${externalToken}&refreshToken=${externalRefreshToken}&redirectUrl=/balance?amount%3D${loadBalance}%26openPaymentModal%3Dtrue`}
                  >
                    <Button
                      style={{
                        backgroundColor: "#FFCB2B",
                        color: "black",
                        padding: "0px 20px",
                      }}
                      type="primary"
                    >
                      Upload Credit
                    </Button>
                  </a>
                </Col>
              </Row>
            )}
          >
            <Row justify={"center"}>
              <InfoCircleOutlined
                style={{
                  fontSize: "48px",
                  marginTop: "20px",
                  color: "#FFCB2B",
                }}
              />
            </Row>
            <h3
              style={{
                textAlign: "center",
                fontWeight: 400,
                margin: "40px 0px",
              }}
            >
              You must load at least <b>${loadBalance}</b> credit for your
              transaction
            </h3>
          </Modal>
          <Modal
            open={isConfirmModal}
            onCancel={() => {
              setIsConfirmModal(false);
            }}
            footer={() => (
              <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                <Col>
                  <Button
                    className="bg-slate-blue border-radius-8 btn-hover-white btn font-color-cloud btn btn-text"
                    onClick={() => {
                      setIsConfirmModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="bg-oneamz-yellow border-radius-8 btn-hover-dark font-color-slate-blue btn btn-text"
                    loading={orderCreateLoading}
                    onClick={() => {
                      onCreateOrder();
                    }}
                  >
                    Confirm
                  </Button>
                </Col>
              </Row>
            )}
          >
            <Row justify={"center"}>
              <WarningIcon
                className="ml-32"
                style={{
                  fontSize: "48px",
                  marginTop: "20px",
                  color: "#FFCB2B",
                }}
              />
            </Row>
            <Row justify={"center"}>
              <Col span={20}>
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: 400,
                    margin: "40px 0px",
                  }}
                >
                  <b>${totalFulfillmentCost}</b> will be deducted from your
                  total credit for the FBM Post payment. Do you agree?
                </h3>
              </Col>
            </Row>
          </Modal>

          {/* <Modal open={isConfirmModal} onCancel={() => { setIsConfirmModal(false) }}
                    footer={(_, { }) => (
                        <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                            <Col>
                                <Button className='bg-slate-blue border-radius-8 btn-hover-white btn font-color-cloud btn btn-text' onClick={() => { setIsConfirmModal(false) }} >İptal Et</Button>
                            </Col>
                            <Col>
                                <Button className='bg-oneamz-yellow border-radius-8 btn-hover-dark font-color-slate-blue btn btn-text' loading={orderCreateLoading} onClick={() => { onCreateOrder() }} >Onayla</Button>
                            </Col>
                        </Row>
                    )}>
                    <Row justify={"center"}>
                        <WarningIcon className='ml-32' style={{ fontSize: "48px", "marginTop": "20px", color: "#FFCB2B" }} />
                    </Row>
                    <Row justify={'center'}>
                        <Col span={20}>
                            <h3 style={{ textAlign: "center", fontWeight: 400, margin: "40px 0px" }}>FBA Gönderisi ödemesi için toplam kredinizden  <b>${totalFulfillmentCost}</b>  düşecektir. Onaylıyor musunuz?</h3>
                        </Col>
                    </Row>
                </Modal>

                <Modal open={isConfirmModal} onCancel={() => { setIsConfirmModal(false) }}
                    footer={(_, { }) => (
                        <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                            <Col>
                                <Button type='primary' style={{ backgroundColor: 'black', color: "pure-white", padding: '0px 30px' }} onClick={() => { setIsConfirmModal(false) }} >İptal Et</Button>
                            </Col>
                            <Col>
                                <Button type='primary' style={{ backgroundColor: '#FFCB2B', color: 'black', padding: '0px 30px' }} loading={orderCreateLoading} onClick={() => { onCreateOrder() }} >Onayla</Button>
                            </Col>
                        </Row>
                    )}>
                    <Row justify={"center"}>
                        <InfoCircleOutlined style={{ fontSize: "48px", "marginTop": "20px", color: "#FFCB2B" }} />
                    </Row>
                    <h3 style={{ textAlign: "center", fontWeight: 400, margin: "40px 0px" }}>FBM Gönderisi ödemesi için toplam kredinizden  <b>${totalFulfillmentCost}</b>  düşecektir. Onaylıyor musunuz?</h3>
                </Modal> */}
          <FBMProgressCard currentStep={current} />
          {/* <Row justify={"center"} align={"middle"}>
                    <Col span={10}>
                        <Steps
                            className='fbm-steps'
                            size="default"
                            current={current}
                            items={items}
                        />
                    </Col>
                </Row> */}
          <div>{steps[current].content}</div>
          <Row
            style={{ marginTop: 24 }}
            justify={current === 0 ? "end" : "space-between"}
          >
            {current > 0 && (
              <Button
                className="btn btn-hover-dark border-radius-12 bg-cloud btn-text font-color-slate-blue"
                size="large"
                onClick={() => prev()}
              >
                Back
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                disabled={totalQty === 0}
                className="btn btn-hover-white border-radius-12 bg-slate-blue btn-text font-color-cloud"
                size="large"
                onClick={() => next()}
              >
                Next Step
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                className="btn btn-hover-white border-radius-12 bg-slate-blue btn-text font-color-cloud"
                size="large"
                onClick={() => getBalance()}
              >
                Create FBM Shipment
              </Button>
            )}
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col span={24}>
              <StarterCard />
            </Col>
          </Row>
          <Card
            className="mt-24"
            bodyStyle={{ border: "none", padding: "0px" }}
            style={{ border: "none" }}
          >
            <Row gutter={24}>
              <Col xl={6} xs={24}>
                <Card className="bg-oneamz-yellow border-radius-md">
                  <p className="text font-color-slate-blue bg-cloud p-8 d-inline border-radius-sm">
                    <b>
                      FBM SHIPMENT CODE <InfoIcon className="wh-14" />
                    </b>
                  </p>
                  <h2 className="h-3 font-color-slate-blue mt-16 mb-0">
                    ONESHIP
                  </h2>
                  <h2 className="h-3 font-color-slate-blue mt-0">
                    ({id}-FBM-{fbmNo})
                  </h2>
                  <p className="text fs-18 font-color-slate-blue">
                    To access the details of your product, you can search within
                    the app with the oneship shipping code.
                  </p>
                  <Row justify={"center"} className="mt-64">
                    <Col>
                      <Image preview={false} src={FbmImg} />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl={18} xs={24}>
                <Card className="bg-cloud border-radius-md p-0">
                  <h1 className="h-1 font-color-slate-blue m-0 p-0 mt-0">
                    Congratulations!
                  </h1>
                  <p className="text fs-24 font-color-slate-blue m-0">
                    Your FBM Submission has been successfully registered
                  </p>
                  <p className="text font-color-slate-blue mt-8">
                    Thank you for your care and attention. You have successfully
                    completed this stage of your OneAMZ FBM process. Your
                    submission has been successfully registered in our system
                    and is now ready for processing.
                  </p>
                  <p className="pretitle font-color-slate-blue mt-32 upper-case">
                    PRODUCT INFORMATION
                  </p>
                  {order?.map((fbmOrder: any, key: any) =>
                    fbmOrder?.items.map((item: any, index: any) => (
                      <FBAResultItemCard item={item} key={index} />
                    ))
                  )}

                  <p className="pretitle font-color-slate-blue mt-32 upper-case">
                    CUSTOMER INFORMATION
                  </p>

                  <h3 className="mt-0 mb-0">
                    <b>{receiver?.customerName}</b>
                  </h3>
                  <p className="mt-8 font-color-slate-blue-08">
                    {receiver?.customerPhone} - {receiver?.customerEmail}
                  </p>
                  <p className="mt-0 font-color-slate-blue-08">
                    {receiver?.customerAddress1} <br />{" "}
                    {receiver?.customerAddress2} {receiver?.customerZipCode}
                  </p>
                  <p className="mt-0 font-color-slate-blue-08">
                    {receiver?.customerCity} {receiver?.customerState}{" "}
                    {receiver?.customerCountryCode}
                  </p>
                  <Row justify={"end"} gutter={8}>
                    <Col>
                      <Button
                        onClick={() => navigate(`/fbm-order/detail/${fbmNo}`)}
                        className=" btn btn-text btn-hover-dark bg-cloud font-color-slate-blue border-slate-blue border-radius-12 mt-48"
                      >
                        Go to Shipment Detail
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => refreshPage()}
                        className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-12 mt-48"
                      >
                        Create New FBM Shipment
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Card>
        </>
      )}
    </>
  );
}

export default FBMCreate;
