import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/XCircle.svg";
import { openNotification } from "../../utils";
import WarehousesService from "../../services/WarehousesService";
import { IWarehousesUpdateRequest } from "../../types/Warehouses";

interface WarehouseDetailProps {
  warehouse: any;
  getWarehouses: () => void;
  open: boolean;
  onCancel: () => void;
}

const { Item } = Form;

const WarehouseDetail: React.FC<WarehouseDetailProps> = ({
  warehouse,
  getWarehouses,
  open,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const countries = [
    { label: "Canada", value: "CA" },
    { label: "Mexico", value: "MX" },
    { label: "US", value: "US" },
    { label: "Czech Republic", value: "CZ" },
    { label: "France", value: "FR" },
    { label: "Germany", value: "DE" },
    { label: "Italy", value: "IT" },
    { label: "Poland", value: "PL" },
    { label: "Spain", value: "ES" },
    { label: "UK", value: "GB" },
    { label: "India", value: "IN" },
    { label: "Japan", value: "JP" },
    { label: "Singapore", value: "SG" },
    { label: "UAE", value: "AE" },
    { label: "Australia", value: "AU" },
  ];

  const warehouseType = [
    { value: "W", label: "Warehouse" },
    { value: "FC", label: "Fulfillment Center" },
  ];

  useEffect(() => {
    if (open && warehouse) {
      form.setFieldsValue({
        countryCode: warehouse?.countryCode,
        type: warehouse?.type,
        code: warehouse?.code,
        address: warehouse?.address,
        name: warehouse?.name,
        addressLine1: warehouse?.addressLine1,
        addressLine2: warehouse?.addressLine2,
        city: warehouse?.city,
        state: warehouse?.state,
        postalCode: warehouse?.postalCode,
        phone: warehouse?.phone,
      });
    }
  }, [open, warehouse, form]);

  const updateWarehouse = async (values: any) => {
    setLoading(true);
    let postObj: IWarehousesUpdateRequest = {
      name: values.name,
      countryCode: values.countryCode,
      type: values.type,
      code: values.code,
      address: values.address,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      phone: values.phone,
    };
    WarehousesService.updateWarehouse(warehouse?.id, postObj)
      .then(() => {
        getWarehouses();
        openNotification(
          "success",
          "Success",
          "Warehouse successfully updated"
        );
      })
      .catch((e: Error) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title={<>{warehouse?.name}</>}
      open={open}
      className="antd-modal-secondary"
      onCancel={onCancel}
      footer={null}
      closeIcon={<img src={closeIcon} height={24} alt="close" />}
    >
      <Form form={form} onFinish={updateWarehouse} layout="vertical">
        <Item
          name="name"
          label="Warehouse Name"
          rules={[
            {
              required: true,
              message: "Warehouse name required",
            },
          ]}
        >
          <Input />
        </Item>
        <Item
          name="countryCode"
          label="Country"
          rules={[
            {
              required: true,
              message: "Select Country",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            options={countries}
          />
        </Item>
        <Item
          name="code"
          label="Warehouse Code"
          rules={[
            {
              required: true,
              message: "Warehouse Code required",
            },
          ]}
        >
          <Input />
        </Item>
        <Item
          name="type"
          label="Warehouse Type"
          rules={[
            {
              required: true,
              message: "Select Warehouse Type!",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            options={warehouseType}
          />
        </Item>
        <Item
          name="address"
          label="Address"
          rules={[
            {
              required: true,
              message: "Address required",
            },
          ]}
        >
          <Input />
        </Item>
        <Item
          name="addressLine1"
          label="Address Line 1"
          rules={[
            {
              required: true,
              message: "Address Line 1 required",
            },
          ]}
        >
          <Input />
        </Item>
        <Item name="addressLine2" label="Address Line 2">
          <Input />
        </Item>
        <Item name="phone" label="Phone">
          <Input />
        </Item>
        <Item name="state" label="State">
          <Input />
        </Item>
        <Item name="city" label="City">
          <Input />
        </Item>
        <Item name="postalCode" label="Postal Code">
          <Input />
        </Item>
        <Item>
          <Row gutter={24}>
            <Col span={12}>
              <Button
                className="btn btn-text btn-hover-dark font-color-slate-blue border-slate-blue w-full border-radius-8"
                onClick={onCancel}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="btn btn-text btn-hover-white font-color-cloud bg-slate-blue w-full border-radius-8"
                htmlType="submit"
                loading={loading}
              >
                Update
              </Button>
            </Col>
          </Row>
        </Item>
      </Form>
    </Modal>
  );
};

export default WarehouseDetail;
