import getItemImg from "../../../assets/V2/FBAV2/fba-create-card.png";
import { Card, Col, Row, Image } from "antd";

const GetItemtoWarehouse = () => {
  return (
    <>
      <Card
        className="bg-slate-blue-05 pointer border-radius-md"
        style={{ minHeight: 165, display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Row align={"middle"} gutter={24}>
          <Col xxl={6} lg={12}>
            <Image preview={false} src={getItemImg} />
          </Col>
          <Col xxl={18} lg={12}>
            <p className="subtitle font-color-pure-white" style={{ margin: 0 }}>
              Check Product to <b>Warehouse</b>
            </p>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default GetItemtoWarehouse;
