import React from "react";
import { Button, Checkbox, Col, Form, Input, Row, Image } from "antd";
import {
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import loginLogo from "../assets/loginLogo.svg";
import loginImage from "../assets/loginImage2.png";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import { openNotification } from "../utils";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const createUser = (values: any) => {
    let userData: any = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
    };

    AuthService.register(userData)
      .then((response: any) => {
        navigate("/verify-email");
      })
      .catch((e) => {
        console.log(e);
        if (e.response.data.code === "oneamz-ff-email-already-exists") {
          openNotification(
            "error",
            "Failed",
            "This email address is already in use."
          );
        }
      });
  };

  const onFinish = (values: any) => {
    createUser(values);
  };

  const passwordValidator = (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error("The password cannot be left blank!"));
    }
    if (value.length < 8) {
      return Promise.reject(
        new Error("Password must be at least 8 characters!")
      );
    }
    if (!value.match(/[A-Z]/)) {
      return Promise.reject(
        new Error("Must contain at least one capital letter!")
      );
    }
    if (!value.match(/[a-z]/)) {
      return Promise.reject(
        new Error("Must contain at least one lowercase letter!")
      );
    }
    if (!value.match(/[0-9]/)) {
      return Promise.reject(new Error("Must contain at least one number!"));
    }
    if (!value.match(/[^A-Za-z0-9]/)) {
      return Promise.reject(
        new Error("Must contain at least one special character!")
      );
    }
    return Promise.resolve();
  };

  return (
    <>
      <Row justify={"space-between"}>
        <Col
          xs={0}
          sm={0}
          md={0}
          lg={16}
          xl={16}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <Image
            style={{ width: "54vw", height: "100vh" }}
            preview={false}
            src={loginImage}
          />
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 11, offset: 13 }}
          xl={{ span: 9, offset: 14 }}
        >
          <Row justify={"center"}>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 20 }}
              md={{ span: 14 }}
              lg={{ span: 24 }}
              xl={24}
              xxl={18}
            >
              <Row
                justify={"center"}
                style={{ margin: "5em 0 0 0", width: "100%" }}
              >
                <Col>
                  <Image
                    style={{ height: "15vh" }}
                    preview={false}
                    src={loginLogo}
                  />
                </Col>
              </Row>
              <Row justify={"center"}>
                <Col span={20}>
                  <Form
                    style={{ marginBottom: "10em" }}
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                  >
                    <h1 style={{ fontSize: "3em", margin: "1em 0 1em 0" }}>
                      Create Account
                    </h1>
                    <Row justify={"space-between"}>
                      <Col span={11}>
                        <p style={{ margin: "0 0 0.4em 0", fontSize: "1.3em" }}>
                          First Name
                        </p>
                        <Form.Item
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your name!",
                            },
                            {
                              pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇ\s]+$/,
                              message:
                                "The name must not contain special characters!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="First Name"
                            size="large"
                            style={{ padding: "0.7em" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <p style={{ margin: "0 0 0.4em 0", fontSize: "1.3em" }}>
                          Last Name
                        </p>
                        <Form.Item
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your last name!",
                            },
                            {
                              pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇ\s]+$/,
                              message:
                                "Last name must not contain special characters!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Last Name"
                            size="large"
                            style={{ padding: "0.7em" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <p
                      style={{
                        margin: "0",
                        padding: " 0 0 1em 0",
                        fontSize: "1.3em",
                      }}
                    >
                      Email
                    </p>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a valid email!",
                          type: "email",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email"
                        size="large"
                        style={{ padding: "0.7em" }}
                      />
                    </Form.Item>
                    <p
                      style={{
                        margin: "0",
                        padding: " 0 0 1em 0",
                        fontSize: "1.3em",
                      }}
                    >
                      Password
                    </p>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password!",
                        },
                        { validator: passwordValidator },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        style={{ padding: "0.7em" }}
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        placeholder="Password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                    <p
                      style={{
                        margin: "0",
                        padding: " 0 0 1em 0",
                        fontSize: "1.3em",
                      }}
                    >
                      Confirm Password
                    </p>
                    <Form.Item
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password again!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject("The passwords don't match!");
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        size="large"
                        style={{ padding: "0.7em" }}
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        placeholder="Password Repeat"
                      />
                    </Form.Item>
                    <div style={{ padding: "20px" }}>
                      <div style={{ marginBottom: "10px" }}>
                        <Form.Item
                          name="bireyselHesapCheckbox"
                          valuePropName="checked"
                          rules={[
                            {
                              required: true,
                              message: "Marking Required!",
                            },
                          ]}
                        >
                          <Checkbox value="bireyselHesap">
                            <a>
                              I accept{" "}
                              <a
                                style={{
                                  color: "#DEA800",
                                  textDecoration: "underline",
                                }}
                              >
                                the Individual Account Agreement{" "}
                              </a>{" "}
                              and its <a style={{ color: "#DEA800" }}>Annex</a>
                              es.
                            </a>
                          </Checkbox>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          name="iletisimBilgileriCheckbox"
                          valuePropName="checked"
                        >
                          <Checkbox value="iletisimBilgileri">
                            I consent to sending commercial electronic messages
                            containing campaigns, promotions and advertisements
                            to my contact information, processing my personal
                            data for this purpose and sharing it with your
                            suppliers.
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </div>
                    <Form.Item>
                      <Button
                        style={{
                          width: "100%",
                          background: "black",
                          height: "4em",
                        }}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        <p
                          style={{
                            fontSize: "1.2em",
                            margin: "0",
                            color: "#FFCB2B",
                          }}
                        >
                          <b>Create Account</b>
                        </p>
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default LoginPage;
