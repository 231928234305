import { IBoxSizeResponse } from "./BoxSize";

interface BoxResponse {
  id?: number;
  name: string;
  boxSize: IBoxSizeResponse;
  isAvailable: boolean;
}

interface BoxUpdateRequest {
  name: string;
}

interface BoxCreateRequest {
  name: string;
  isAvailable: boolean;
  boxSizeId: number;
}

interface BoxesRequest {
  page: number;
  size: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";
}

export class IBoxResponse implements BoxResponse {
  id?: number;
  name: string;
  boxSize: IBoxSizeResponse;
  isAvailable: boolean;

  constructor(obj: BoxResponse) {
    this.id = obj.id;
    this.name = obj.name;
    this.boxSize = obj.boxSize;
    this.isAvailable = obj.isAvailable;
  }
}

export class IBoxUpdateRequest implements BoxUpdateRequest {
  name: string;

  constructor(obj: BoxUpdateRequest) {
    this.name = obj.name;
  }
}

export class IBoxCreateRequest implements BoxCreateRequest {
  name: string;
  isAvailable: boolean;
  boxSizeId: number;

  constructor(obj: BoxCreateRequest) {
    this.name = obj.name;
    this.isAvailable = obj.isAvailable;
    this.boxSizeId = obj.boxSizeId;
  }
}

export class IBoxesRequest implements BoxesRequest {
  page: number;
  size: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";

  constructor(obj: BoxesRequest) {
    this.page = obj.page;
    this.size = obj.size;
    this.search = obj.search ?? "";
    this.sort = obj.sort;
    this.direction = obj.direction;
  }
}

interface PaginatedBoxesResponse {
  data: IBoxResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class IPaginatedBoxesResponse implements PaginatedBoxesResponse {
  data: IBoxResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;

  constructor(obj: PaginatedBoxesResponse) {
    this.data = obj.data.map((box) => new IBoxResponse(box));
    this.page = obj.page;
    this.size = obj.size;
    this.totalElements = obj.totalElements;
    this.totalPages = obj.totalPages;
  }
}
