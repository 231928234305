import { Col, Row } from "antd";
import { NavLink } from "react-router-dom";

const SettingsRouter = () => {
  const linkStyle = (isActive: boolean) => {
    return isActive ? "font-color-slate-blue" : "font-color-slate-blue-05";
  };

  return (
    <Row>
      <Col style={{ padding: "1rem" }}>
        <NavLink
          className={({ isActive }) => linkStyle(isActive)}
          to="/settings/services"
        >
          <p className="link fs-16">
            <b>Additional Service List</b>
          </p>
        </NavLink>
      </Col>
      <Col style={{ padding: "1rem" }}>
        <NavLink
          className={({ isActive }) => linkStyle(isActive)}
          to="/settings/warehouses"
        >
          <p className="link fs-16">
            <b>Amazon FBA Warehouses</b>
          </p>
        </NavLink>
      </Col>
      <Col style={{ padding: "1rem" }}>
        <NavLink
          className={({ isActive }) => linkStyle(isActive)}
          to="/settings/boxes"
        >
          <p className="link fs-16">
            <b>Boxes</b>
          </p>
        </NavLink>
      </Col>
      <Col style={{ padding: "1rem" }}>
        <NavLink
          className={({ isActive }) => linkStyle(isActive)}
          to="/settings/warehouse-management"
        >
          <p className="link fs-16">
            <b>Warehouse Management</b>
          </p>
        </NavLink>
      </Col>
      <Col style={{ padding: "1rem" }}>
        <NavLink
          className={({ isActive }) => linkStyle(isActive)}
          to="/settings/users"
        >
          <p className="link fs-16">
            <b>Users</b>
          </p>
        </NavLink>
      </Col>
      <Col style={{ padding: "1rem" }}>
        <NavLink
          className={({ isActive }) => linkStyle(isActive)}
          to="/settings/roles"
        >
          <p className="link fs-16">
            <b>Roles</b>
          </p>
        </NavLink>
      </Col>
    </Row>
  );
};

export default SettingsRouter;
