import http from "../http-common";
import {
  ICustomersRequest,
  ICustomersResponse,
  IPaginatedCustomersResponse,
  ICustomersCreateRequest,
  ICustomersUpdateRequest,
} from "../types/Customers";

const getCustomers = (filterCriteria: ICustomersRequest) => {
  return http.get<IPaginatedCustomersResponse>(
    `/api/v2/customers${filterCriteria}`
  );
};

const getCustomersById = (id: number) => {
  return http.get<ICustomersResponse>(`/api/v2/customers/${id}`);
};

const createCustomer = (requestBody: ICustomersCreateRequest) => {
  return http.post<ICustomersResponse>(`/api/v2/customers`, requestBody);
};

const updateCustomer = (id: number, requestBody: ICustomersUpdateRequest) => {
  return http.put<ICustomersResponse>(`/api/v2/customers/${id}`, requestBody);
};

const deleteCustomer = (id: number) => {
  return http.delete<ICustomersResponse>(`/api/v2/customers/${id}`);
};

const CustomersService = {
  getCustomers,
  getCustomersById,
  createCustomer,
  updateCustomer,
  deleteCustomer,
};

export default CustomersService;
