import { Col, Row, Card } from "antd";
import React from "react";
import AmazonProductInfo from "../../../components/Common/AmazonProductInfo";
import AmazonProductImage from "../../../components/Common/AmazonProductImage";

interface FBAShipmentItemCardManagerProps {
  item: any;
}
const FBAResultItemCard: React.FC<FBAShipmentItemCardManagerProps> = ({
  item,
}) => {
  return (
    <>
      <Card>
        <Row align={"top"} justify={"start"}>
          <Col span={16}>
            <Row gutter={[24, 24]} align={"middle"} justify={"start"}>
              <Col>
                <AmazonProductImage asin={item?.product?.asin} />
              </Col>
              <Col span={18}>
                <AmazonProductInfo
                  asin={item?.product?.asin}
                  title={item?.product?.title}
                />
              </Col>
            </Row>
          </Col>
          <Col span={5}>
            <Row>
              <Col span={24}>
                <p className="pretitle font-color-slate-blue upper-case">
                  ADDITIONAL SERVICES
                </p>
              </Col>
              <Col span={24}>
                {item?.additionalServices.map((service: any) => (
                  <>
                    <Row key={service?.additionalService?.id}>
                      <Col span={24}>
                        <p className="text-small font-color-slate-blue-05 m-0">
                          {service?.additionalService?.name}
                        </p>
                      </Col>
                    </Row>
                  </>
                ))}
              </Col>
            </Row>
          </Col>
          <Col span={3}>
            <Row>
              <Col span={24}>
                <Row justify={"center"}>
                  <Col>
                    <p className="pretitle font-color-slate-blue upper-case">
                      QUANTITY
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify={"center"}>
                  <Col>
                    <p className="btn-text-sm font-color-slate-blue-05 m-0">
                      {item?.quantity}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default FBAResultItemCard;
