import { Col, Collapse, Row, Input, Divider } from "antd";
import React, { useState } from "react";
import AmazonProductImageAvatar from "../../../components/Common/AmazonProductImageAvatar";
import OrderAlert from "../../../components/Order/OrderAlert";
import AmazonProductInfo from "../../../components/Common/AmazonProductInfo";
import TextArea from "antd/es/input/TextArea";
import { formatMoney } from "../../../utils";
import OneamzShippingLabel from "../../../components/FBADetail/OneamzShippingLabel";
import { debounce } from "lodash";
import { IFbaOrderResponse } from "../../../types/FbaOrder";

interface FBAShipmentItemCardManagerProps {
  item: any;
  order: IFbaOrderResponse;
  orderItem: any;
}
const FBAShipmentItemCardManager: React.FC<FBAShipmentItemCardManagerProps> = ({
  item,
  order,
  orderItem,
}) => {
  const [missing, setMissing] = useState(orderItem.missing || 0);
  const [damaged, setDamaged] = useState(orderItem.damage || 0);
  const [received, setReceived] = useState(orderItem.received || 0);
  const mandatoryServices = item.additionalServices.filter(
    (service: any) => service.additionalService.required
  );
  const optionalServices = item.additionalServices.filter(
    (service: any) => !service.additionalService.required
  );
  // const readOnly = order.type === "ORDER" ? true : false;

  const updateDamageQty = debounce((orderItemId: any, value: any) => {
    const quantity = orderItem.qty;
    const newValue = parseInt(value, 10);

    // if (newValue >= 0 && newValue !== null) {
    //   if (received + newValue <= quantity + missing) {
    //     ClaimOrderService.updateClaimOrderItem(orderItemId, {
    //       damaged: newValue,
    //       received: received,
    //     })
    //       .then((response: any) => {
    //         setReceived(response.data.received);
    //         setDamaged(response.data.damage);
    //         setMissing(response.data.missing);
    //         openNotification(
    //           "success",
    //           "Success",
    //           "Damaged quantity updated successfully."
    //         );
    //       })
    //       .catch((e: Error) => {
    //         openNotification(
    //           "error",
    //           "Error",
    //           "Failed to update damaged quantity."
    //         );
    //       });
    //   } else {
    //     openNotification(
    //       "error",
    //       "Error",
    //       "Damaged quantity exceeds the allowed limit."
    //     );
    //     console.error("Damaged quantity exceeds the allowed limit.");
    //   }
    // }
    // return false;
  }, 300);

  const updateReceiveQty = debounce((orderItemId: any, value: any) => {
    const quantity = orderItem.qty;
    const newValue = parseInt(value, 10);

    // if (newValue >= 0 && newValue !== null) {
    //   if (damaged + newValue <= quantity + missing) {
    //     ClaimOrderService.updateClaimOrderItem(orderItemId, {
    //       received: newValue,
    //       damaged: damaged,
    //     })
    //       .then((response: any) => {
    //         setReceived(response.data.received);
    //         setDamaged(response.data.damage);
    //         setMissing(response.data.missing);
    //         openNotification(
    //           "success",
    //           "Success",
    //           "Received quantity updated successfully."
    //         );
    //       })
    //       .catch((e: Error) => {
    //         openNotification(
    //           "error",
    //           "Error",
    //           "Failed to update received quantity."
    //         );
    //       });
    //   } else {
    //     openNotification(
    //       "error",
    //       "Error",
    //       "Received quantity exceeds the allowed limit."
    //     );
    //     console.error("Received quantity exceeds the allowed limit.");
    //   }
    // }
    // return false;
  }, 300);

  const updateNote = debounce((orderItemId: any, e: any) => {
    // ClaimOrderService.updateClaimOrderItem(orderItemId, {
    //   note: e.target.value,
    // })
    //   .then((response: any) => {
    //     openNotification("success", "Success", "Note updated successfully.");
    //   })
    //   .catch((e: Error) => {
    //     openNotification("error", "Error", "Failed to update note.");
    //   });
  }, 300);

  return (
    <>
      <Collapse
        className="antd-order-detail-collapse border-radius-md mb-24"
        expandIconPosition={"end"}
        items={[
          {
            key: "1",
            label: (
              <>
                <Row
                  gutter={[24, 24]}
                  align={"middle"}
                  justify={"space-between"}
                >
                  <Col span={3}>
                    <AmazonProductImageAvatar orderItem={item} />
                  </Col>
                  <Col span={10}>
                    <Row>
                      <AmazonProductInfo
                        asin={item.product.asin}
                        title={item.product.title}
                      />
                    </Row>
                  </Col>
                  <Col span={8}>
                    <OneamzShippingLabel order={order} orderItem={orderItem} />
                  </Col>
                  <Col className="text-center" span={3}>
                    <OrderAlert order={order} />
                  </Col>
                </Row>
              </>
            ),
            children: (
              <>
                <Row justify={"space-between"} align={"top"}>
                  <Col xl={11}>
                    <h4 className="text mt-0 mb-8">
                      <b>FBA REQUIRED SERVICES</b>
                    </h4>
                    <Row>
                      <Col span={9}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Service</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Unit Price</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Quantity</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Total</b>
                        </p>
                      </Col>
                    </Row>
                    {mandatoryServices.map((service: any) => (
                      <>
                        <Row key={service?.id}>
                          <Col span={9}>
                            <p className="text-small font-color-slate-blue-05 mb-8">
                              <b>{service?.additionalService?.name}</b>
                            </p>
                          </Col>
                          <Col className="text-center" span={5}>
                            <p className="text-small font-color-slate-blue-05 mb-8">
                              <b>
                                {" "}
                                {formatMoney(service?.additionalService?.price)}
                              </b>
                            </p>
                          </Col>
                          <Col className="text-center" span={5}>
                            <p className="text-small font-color-slate-blue-05 mb-8">
                              <b>{service?.quantity}</b>
                            </p>
                          </Col>
                          <Col className="text-center" span={5}>
                            <p className="text-small font-color-slate-blue mb-8">
                              <b> {formatMoney(service?.price)}</b>
                            </p>
                          </Col>
                        </Row>
                        <Divider className="m-0 mt-8" />
                      </>
                    ))}
                  </Col>

                  <Col xl={11}>
                    <h4 className="text mt-0 mb-8">
                      <b>ADDITIONAL SERVICES</b>
                    </h4>
                    <Row>
                      <Col span={9}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Service</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Unit Price</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Quantity</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Total</b>
                        </p>
                      </Col>
                    </Row>
                    {optionalServices.map((service: any) => (
                      <>
                        <Row key={service?.id}>
                          <Col span={9}>
                            <p className="text-small font-color-slate-blue-05 mb-8">
                              <b>{service?.additionalService?.name}</b>
                            </p>
                          </Col>
                          <Col className="text-center" span={5}>
                            <p className="text-small font-color-slate-blue-05 mb-8">
                              <b>
                                {" "}
                                {formatMoney(service?.additionalService?.price)}
                              </b>
                            </p>
                          </Col>
                          <Col className="text-center" span={5}>
                            <p className="text-small font-color-slate-blue-05 mb-8">
                              <b>{service?.quantity}</b>
                            </p>
                          </Col>
                          <Col className="text-center" span={5}>
                            <p className="text-small font-color-slate-blue mb-8">
                              <b> {formatMoney(service?.price)}</b>
                            </p>
                          </Col>
                        </Row>
                        <Divider className="m-0 mt-8" />
                      </>
                    ))}
                  </Col>
                </Row>
                <Row gutter={[24, 24]} className="mt-24">
                  <Col span={12}>
                    <p className="text-sm fs-12 m-0">
                      <b>QUANTITY</b>
                    </p>
                    <Input
                      className="amz-input w-full"
                      placeholder="Quantity"
                      readOnly
                      defaultValue={orderItem.quantity}
                      disabled
                    />
                  </Col>
                  <Col span={12}>
                    <p className="text-sm fs-12 m-0">
                      <b>MISSING</b>
                    </p>
                    <Input
                      className="amz-input w-full"
                      placeholder="Missing"
                      // readOnly={readOnly}
                      value={missing}
                      disabled
                    />
                  </Col>
                  <Col span={12}>
                    <p className="text-sm fs-12 m-0">
                      <b>DAMAGED</b>
                    </p>
                    <Input
                      type="number"
                      min={0}
                      className="amz-input w-full"
                      placeholder="Damaged"
                      // readOnly={readOnly}
                      defaultValue={damaged}
                      onChange={(e) =>
                        updateDamageQty(orderItem.id, e.target.value)
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <p className="text-sm fs-12 m-0">
                      <b>RECEIVED</b>
                    </p>
                    <Input
                      type="number"
                      min={0}
                      className="amz-input w-full"
                      placeholder="Received"
                      // readOnly={readOnly}
                      defaultValue={received}
                      onChange={(e) =>
                        updateReceiveQty(orderItem.id, e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={[24, 24]} className="mt-24">
                  <Col span={24}>
                    <p className="text-sm fs-12 m-0">
                      <b>NOTE</b>
                    </p>
                    <TextArea
                      // readOnly={readOnly}
                      placeholder="Write a note for information about the product..."
                      defaultValue={orderItem.note}
                      onChange={(value) => updateNote(orderItem.id, value)}
                      style={{ height: "150px" }}
                    />
                  </Col>
                </Row>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default FBAShipmentItemCardManager;
