interface CustomersResponse {
  fullName: string;
  phoneNumber: string;
  email: string;
  country: string;
  state: string;
  city: string;
  postCode: string;
  addressLine1: string;
  addressLine2?: string;
}

interface CustomersUpdateRequest {
  fullName: string;
  phoneNumber: string;
  email: string;
  country: string;
  state: string;
  city: string;
  postCode: string;
  addressLine1: string;
  addressLine2?: string;
}

interface CustomersCreateRequest {
  fullName: string;
  phoneNumber: string;
  email: string;
  country: string;
  state: string;
  city: string;
  postCode: string;
  addressLine1: string;
  addressLine2?: string;
}

interface CustomersRequest {
  page?: number;
  size?: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";
}

export class ICustomersResponse implements CustomersResponse {
  fullName: string;
  phoneNumber: string;
  email: string;
  country: string;
  state: string;
  city: string;
  postCode: string;
  addressLine1: string;
  addressLine2?: string;

  constructor(obj: CustomersResponse) {
    this.fullName = obj.fullName;
    this.phoneNumber = obj.phoneNumber;
    this.email = obj.email;
    this.country = obj.country;
    this.state = obj.state;
    this.city = obj.city;
    this.postCode = obj.postCode;
    this.addressLine1 = obj.addressLine1;
    this.addressLine2 = obj.addressLine2;
  }
}

export class ICustomersUpdateRequest implements CustomersUpdateRequest {
  fullName: string;
  phoneNumber: string;
  email: string;
  country: string;
  state: string;
  city: string;
  postCode: string;
  addressLine1: string;
  addressLine2?: string;

  constructor(obj: CustomersUpdateRequest) {
    this.fullName = obj.fullName;
    this.phoneNumber = obj.phoneNumber;
    this.email = obj.email;
    this.country = obj.country;
    this.state = obj.state;
    this.city = obj.city;
    this.postCode = obj.postCode;
    this.addressLine1 = obj.addressLine1;
    this.addressLine2 = obj.addressLine2;
  }
}

export class ICustomersCreateRequest implements CustomersCreateRequest {
  fullName: string;
  phoneNumber: string;
  email: string;
  country: string;
  state: string;
  city: string;
  postCode: string;
  addressLine1: string;
  addressLine2?: string;

  constructor(obj: CustomersCreateRequest) {
    this.fullName = obj.fullName;
    this.phoneNumber = obj.phoneNumber;
    this.email = obj.email;
    this.country = obj.country;
    this.state = obj.state;
    this.city = obj.city;
    this.postCode = obj.postCode;
    this.addressLine1 = obj.addressLine1;
    this.addressLine2 = obj.addressLine2;
  }
}

export class ICustomersRequest implements CustomersRequest {
  page?: number;
  size?: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";

  constructor(obj: CustomersRequest) {
    this.page = obj.page;
    this.size = obj.size;
    this.search = obj.search;
    this.sort = obj.sort;
    this.direction = obj.direction;
  }
}

interface PaginatedCustomersResponse {
  data: CustomersResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class IPaginatedCustomersResponse implements PaginatedCustomersResponse {
  data: ICustomersResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;

  constructor(obj: PaginatedCustomersResponse) {
    this.data = obj.data.map((customer) => new ICustomersResponse(customer));
    this.page = obj.page;
    this.size = obj.size;
    this.totalElements = obj.totalElements;
    this.totalPages = obj.totalPages;
  }
}
