import { Card, Col, Input, Row } from "antd";
import AmazonProductInfo from "../../../components/Common/AmazonProductInfo";
import AmazonProductImageAvatar from "../../../components/Common/AmazonProductImageAvatar";
import { openNotification } from "../../../utils";
import { debounce } from "lodash";
import { useState } from "react";

interface OrderDetailCardProps {
  orderItem: any;
}

const StorageItemCardManager: React.FC<OrderDetailCardProps> = ({
  orderItem,
}) => {
  const [missing, setMissing] = useState(orderItem?.missing || 0);
  const [damaged, setDamaged] = useState(orderItem?.damage || 0);
  const [received, setReceived] = useState(orderItem?.received || 0);

  const updateDamageQty = debounce((orderItemId: any, value: any) => {
    const quantity = orderItem.qty;
    const newValue = parseInt(value, 10);

    // if (newValue >= 0 && newValue !== null) {
    //   if (received + newValue <= quantity + missing) {
    //     ClaimOrderService.updateClaimOrderItem(orderItemId, {
    //       damaged: newValue,
    //       received: received,
    //     })
    //       .then((response: any) => {
    //         setReceived(response.data.received);
    //         setDamaged(response.data.damage);
    //         setMissing(response.data.missing);
    //         openNotification(
    //           "success",
    //           "Success",
    //           "Damaged quantity updated successfully."
    //         );
    //       })
    //       .catch((e: Error) => {
    //         openNotification(
    //           "error",
    //           "Error",
    //           "Failed to update damaged quantity."
    //         );
    //       });
    //   } else {
    //     openNotification(
    //       "error",
    //       "Error",
    //       "Damaged quantity exceeds the allowed limit."
    //     );
    //     console.error("Damaged quantity exceeds the allowed limit.");
    //   }
    // }
    // return false;
  }, 300);

  const updateReceiveQty = debounce((orderItemId: any, value: any) => {
    const quantity = orderItem.qty;
    const newValue = parseInt(value, 10);

    // if (newValue >= 0 && newValue !== null) {
    //   if (damaged + newValue <= quantity + missing) {
    //     ClaimOrderService.updateClaimOrderItem(orderItemId, {
    //       received: newValue,
    //       damaged: damaged,
    //     })
    //       .then((response: any) => {
    //         setReceived(response.data.received);
    //         setDamaged(response.data.damage);
    //         setMissing(response.data.missing);
    //         openNotification(
    //           "success",
    //           "Success",
    //           "Received quantity updated successfully."
    //         );
    //       })
    //       .catch((e: Error) => {
    //         openNotification(
    //           "error",
    //           "Error",
    //           "Failed to update received quantity."
    //         );
    //       });
    //   } else {
    //     openNotification(
    //       "error",
    //       "Error",
    //       "Received quantity exceeds the allowed limit."
    //     );
    //     console.error("Received quantity exceeds the allowed limit.");
    //   }
    // }
    // return false;
  }, 300);

  // const updateNote = debounce((orderItemId: any, e: any) => {
  //   ClaimOrderService.updateClaimOrderItem(orderItemId, {
  //     note: e.target.value,
  //   })
  //     .then((response: any) => {
  //       openNotification("success", "Success", "Note updated successfully.");
  //     })
  //     .catch((e: Error) => {
  //       openNotification("error", "Error", "Failed to update note.");
  //     });
  // }, 300);

  return (
    <>
      <Card className="border-radius-md w-full p-24 mt-24">
        <Row align={"top"} justify={"start"}>
          <Col xl={16} md={8} xs={24}>
            <Row>
              <Col className="mr-24">
                <AmazonProductImageAvatar orderItem={orderItem} />
              </Col>
              <Col span={16}>
                <AmazonProductInfo
                  asin={orderItem?.product?.asin}
                  title={orderItem?.product?.title}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={4} md={8} xs={12}>
            <p className="text-sm fs-12 m-0">
              <b>DIMENSIONS</b>
            </p>
            <p className="text-sm fs-12 font-color-slate-blue-06 m-0">
              {orderItem?.product?.width} X {orderItem?.product?.length}{" "}
              X {orderItem?.product?.height} <b>IN</b>
            </p>
            <p className="text-sm fs-12 font-color-slate-blue-06 m-0">
              {orderItem?.product?.weight} <b>LB</b>
            </p>
          </Col>
        </Row>
        <Row gutter={[24, 24]} className="mt-24">
          <Col span={12}>
            <p className="text-sm fs-12 m-0">
              <b>QUANTITY</b>
            </p>
            <Input
              className="amz-input w-full"
              placeholder="Quantity"
              // readOnly
              value={Number(orderItem?.quantity)}
              disabled
            />
          </Col>
          <Col span={12}>
            <p className="text-sm fs-12 m-0">
              <b>MISSING</b>
            </p>
            <Input
              className="amz-input w-full"
              placeholder="Missing"
              // readOnly={readOnly}
              value={missing}
              disabled
            />
          </Col>
          <Col span={12}>
            <p className="text-sm fs-12 m-0">
              <b>DAMAGED</b>
            </p>
            <Input
              type="number"
              min={0}
              className="amz-input w-full"
              placeholder="Damaged"
              // readOnly={readOnly}
              defaultValue={damaged}
              onChange={(e) => updateDamageQty(orderItem.id, e.target.value)}
            />
          </Col>
          <Col span={12}>
            <p className="text-sm fs-12 m-0">
              <b>RECEIVED</b>
            </p>
            <Input
              type="number"
              min={0}
              className="amz-input w-full"
              placeholder="Received"
              // readOnly={readOnly}
              defaultValue={received}
              onChange={(e) => updateReceiveQty(orderItem.id, e.target.value)}
            />
          </Col>
        </Row>
        {/* <Row gutter={[24, 24]} className="mt-24">
          <Col span={24}>
            <p className="text-sm fs-12 m-0">
              <b>NOTE</b>
            </p>
            <TextArea
              // showCount
              // readOnly={readOnly}
              placeholder="Write a note for information about the product..."
              defaultValue={orderItem.note}
              onChange={(value) => updateNote(orderItem.id, value)}
              style={{ height: "150px" }}
            />
          </Col>
        </Row> */}
      </Card>
    </>
  );
};

export default StorageItemCardManager;
