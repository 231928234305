import { Button, Col, Form, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import closeIcon from "../../../../assets/XCircle.svg";
import { openNotification } from "../../../../utils";
import { IBoxUpdateRequest } from "../../../../types/Box";
import BoxService from "../../../../services/BoxService";

interface BoxDetailProps {
  box: any;
  getBoxes: () => void;
  open: boolean;
  onCancel: () => void;
}

const { Item } = Form;

const BoxDetail: React.FC<BoxDetailProps> = ({
  box,
  getBoxes,
  open,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      form.resetFields();
      if (box) {
        form.setFieldsValue({
          name: box.name,
        });
      }
    }
  }, [open, box, form]);

  const updateBox = (values: any) => {
    if (!box || !box.id) {
      openNotification("error", "Error", "Box ID is missing");
      return;
    }

    setLoading(true);
    const postObj: IBoxUpdateRequest = {
      name: values.name,
    };

    BoxService.updateBox(box.id, postObj)
      .then(() => {
        getBoxes();
        onCancel();
        openNotification("success", "Success", "Box successfully updated");
      })
      .catch((e: Error) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title={<>{box?.name}</>}
      open={open}
      className="antd-modal-secondary"
      onCancel={onCancel}
      footer={null}
      closeIcon={<img src={closeIcon} height={24} alt="close" />}
    >
      <Form
        form={form}
        onFinish={updateBox}
        layout="vertical"
        initialValues={{ name: box?.name }}
      >
        <Item
          name="name"
          label="Box Name"
          rules={[
            {
              required: true,
              message: "Box name required",
            },
          ]}
        >
          <Input />
        </Item>
        <Item>
          <Row gutter={24}>
            <Col span={12}>
              <Button
                className="btn btn-text btn-hover-dark font-color-slate-blue border-slate-blue w-full border-radius-8"
                onClick={onCancel}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="btn btn-text btn-hover-white font-color-cloud bg-slate-blue w-full border-radius-8"
                htmlType="submit"
                loading={loading}
              >
                Update
              </Button>
            </Col>
          </Row>
        </Item>
      </Form>
    </Modal>
  );
};

export default BoxDetail;
