import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import { clearStorage, openNotification } from "../utils";
import { useLocation } from "react-router-dom";
import OneamzAppService from "../services/OneamzAppService";
import moment from "moment";

const VerifyExternalToken: React.FC = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const externalAccessToken: any = query.get("accessToken");
  const externalRefreshToken: any = query.get("refreshToken");

  useEffect(() => {
    localStorage.setItem("externalAccessToken", externalAccessToken);
    localStorage.setItem("externalRefreshToken", externalRefreshToken);
    getRefreshToken(externalRefreshToken);
    // eslint-disable-next-line
  }, []);

  const getRefreshToken = (externalRefreshToken: string) => {
    let refreshTokenData = {
      refreshToken: externalRefreshToken,
    };

    OneamzAppService.refrestToken(refreshTokenData)
      .then((response: any) => {
        if (response.data.code === 401) {
          clearStorage();
          window.location.href = "/login";
        } else {
          const currentTime = moment();
          localStorage.setItem(
            "lastCheckTime",
            currentTime.format("YYYY-MM-DD HH:mm:ss")
          );

          let data = response.data;
          let externalAccessToken = data.accessToken;
          let externalRefreshToken = data.refreshToken;
          localStorage.setItem("externalAccessToken", externalAccessToken);
          localStorage.setItem("externalRefreshToken", externalRefreshToken);
          getToken(externalAccessToken);
        }
      })
      .catch((e: Error) => {
        clearStorage();
        window.location.href = "/login";
      });
  };

  const getToken = (externalAccessToken: string) => {
    let data = {
      externalToken: externalAccessToken,
    };

    AuthService.authToken(data)
      .then((response: any) => {
        let accessToken = response.data.accessToken;
        let refreshToken = response.data.refreshToken;
        let accessTokenExpiredAt = response.data.accessTokenExpiredAt;
        let refreshTokenExpiredAt = response.data.refreshTokenExpiredAt;

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("accessTokenExpiredAt", accessTokenExpiredAt);
        localStorage.setItem("refreshTokenExpiredAt", refreshTokenExpiredAt);

        UserService.getMe().then((response: any) => {
          const data = response.data;
          localStorage.setItem("firstName", data.firstName);
          localStorage.setItem("lastName", data.lastName);
          localStorage.setItem("id", data.id);
          localStorage.setItem("email", data.email);
          localStorage.setItem("userRole", "CUSTOMER");

          if (data.roles.length > 0) {
            let role = data.roles[0].name;
            localStorage.setItem("userRole", role);
          }

          navigate("/");
        });
      })
      .catch((e: Error) => {
        openNotification(
          "error",
          "Failed",
          "Email could not be verified contact administrator"
        );
        setLoading(false);
      });
  };

  return <></>;
};

export default VerifyExternalToken;
