import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/XCircle.svg";
import { openNotification } from "../../utils";
import WarehousesService from "../../services/WarehousesService";
import { IWarehousesCreateRequest } from "../../types/Warehouses";

interface WarehouseDetailProps {
  onHandleWarehouseList: () => void;
}

const { Item } = Form;

const CreateWarehouse: React.FC<WarehouseDetailProps> = ({
  onHandleWarehouseList,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const countries = [
    { label: "Canada", value: "CA" },
    { label: "Mexico", value: "MX" },
    { label: "US", value: "US" },
    { label: "Czech Republic", value: "CZ" },
    { label: "France", value: "FR" },
    { label: "Germany", value: "DE" },
    { label: "Italy", value: "IT" },
    { label: "Poland", value: "PL" },
    { label: "Spain", value: "ES" },
    { label: "UK", value: "GB" },
    { label: "India", value: "IN" },
    { label: "Japan", value: "JP" },
    { label: "Singapore", value: "SG" },
    { label: "UAE", value: "AE" },
    { label: "Australia", value: "AU" },
  ];

  const warehouseType = [
    { value: "W", label: "Warehouse" },
    { value: "FC", label: "Fulfillment Center" },
  ];

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  const createWarehouse = (values: any) => {
    setLoading(true);
    let postObj: IWarehousesCreateRequest = {
      name: values.name,
      countryCode: values.countryCode,
      type: values.type,
      code: values.code,
      address: values.address,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      phone: values.phone,
    };
    WarehousesService.createWarehouse(postObj)
      .then(() => {
        onHandleWarehouseList();
        openNotification("success", "Success", "Warehouse added successfully");
        setIsModalVisible(false);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Row justify="end">
        <Col span={24}>
          <Button
            className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud w-full border-radius-8"
            onClick={() => setIsModalVisible(true)}
          >
            Add Warehouse
          </Button>
        </Col>
      </Row>

      <Modal
        title={<>Add Warehouse</>}
        open={isModalVisible}
        className="antd-modal-secondary"
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        closeIcon={<img src={closeIcon} height={24} alt="close" />}
      >
        <Form form={form} onFinish={createWarehouse} layout="vertical">
          <Item
            name="name"
            label="Warehouse Name"
            rules={[
              {
                required: true,
                message: "Warehouse name required",
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            name="countryCode"
            label="Country"
            rules={[
              {
                required: true,
                message: "Select Country",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              options={countries}
            />
          </Item>
          <Item
            name="code"
            label="Warehouse Code"
            rules={[
              {
                required: true,
                message: "Warehouse Code required",
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            name="type"
            label="Warehouse Type"
            rules={[
              {
                required: true,
                message: "Select Warehouse Type!",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              options={warehouseType}
            />
          </Item>
          <Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: "Address required",
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            name="addressLine1"
            label="Address Line 1"
            rules={[
              {
                required: true,
                message: "Address Line 1 required",
              },
            ]}
          >
            <Input />
          </Item>
          <Item name="addressLine2" label="Address Line 2">
            <Input />
          </Item>
          <Item name="phone" label="Phone">
            <Input />
          </Item>
          <Item name="state" label="State">
            <Input />
          </Item>
          <Item name="city" label="City">
            <Input />
          </Item>
          <Item name="postalCode" label="Postal Code">
            <Input />
          </Item>
          <Item>
            <Row gutter={24}>
              <Col span={12}>
                <Button
                  className="btn btn-text btn-hover-dark font-color-slate-blue border-slate-blue w-full border-radius-8"
                  onClick={() => setIsModalVisible(false)}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="btn btn-text btn-hover-white font-color-cloud bg-slate-blue w-full border-radius-8"
                  htmlType="submit"
                  loading={loading}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateWarehouse;
