interface UserResponse {
  id?: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

interface UserRequest {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export class IUserResponse implements UserResponse {
  id?: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;

  constructor(obj: UserResponse) {
    this.id = obj.id;
    this.email = obj.email;
    this.firstName = obj.firstName;
    this.lastName = obj.lastName;
    this.phoneNumber = obj.phoneNumber;
  }
}

export class IUserRequest implements UserRequest {
  firstName: string;
  lastName: string;
  phoneNumber: string;

  constructor(obj: UserRequest) {
    this.firstName = obj.firstName;
    this.lastName = obj.lastName;
    this.phoneNumber = obj.phoneNumber;
  }
}
