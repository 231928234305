import { Col, Collapse, Row } from "antd";
import React, { useEffect, useState } from "react";
import AmazonProductImageAvatar from "../../../components/Common/AmazonProductImageAvatar";
import AmazonProductInfo from "../../../components/Common/AmazonProductInfo";
import Receiver from "../../../components/FBADetail/Receiver";
import OneamzShippingLabel from "../../../components/FBADetail/OneamzShippingLabel";
import { IFbmOrderResponse } from "../../../types/FbmOrder";
import FbmOrderService from "../../../services/FbmOrderService";

interface FBAShipmentItemCardManagerProps {
  item: any;
  order: IFbmOrderResponse;
  orderItem: any;
  setQty: (id: any, qty: any) => void;
}
const FBMShipmentItemCardManager: React.FC<FBAShipmentItemCardManagerProps> = ({
  item,
  order,
  orderItem,
}) => {
  const [receiver, setReceiver] = useState<any>(null);

  const getReceiver = (id: any) => {
    const request: any = `?userId=${id}`;
    FbmOrderService.getFbmOrders(request)
      .then((response: any) => {
        setReceiver(response.data.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getReceiver(order.id);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Collapse
        className="antd-order-detail-collapse border-radius-md mb-24"
        expandIconPosition={"end"}
        items={[
          {
            key: "1",
            label: (
              <>
                <Row align={"middle"} gutter={[24, 24]}>
                  <Col span={4}>
                    <AmazonProductImageAvatar orderItem={item} />
                  </Col>

                  <Col span={10}>
                    <Row>
                      <AmazonProductInfo
                        asin={item.product.asin}
                        title={item.product.title}
                      />
                    </Row>
                  </Col>
                  <Col span={8}>
                    <OneamzShippingLabel
                      order={order}
                      orderItem={orderItem}
                      receiver={receiver}
                    />
                  </Col>
                </Row>
              </>
            ),
            children: (
              <>
                <Receiver receiver={order} />
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default FBMShipmentItemCardManager;
