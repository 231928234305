import { Card } from "antd";
import { useEffect, useState } from "react";
import OneamzAppService from "../../../services/OneamzAppService";
import { formatMoney } from "../../../utils";

const TotalBalance = () => {
  const [balance, setBalance] = useState<number>();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getBalance();
    // eslint-disable-next-line
  }, []);

  const getBalance = () => {
    setLoading(true);
    OneamzAppService.getBalance()
      .then((response: any) => {
        let CurrentBalance: number = response.data.balance;
        setBalance(CurrentBalance);
        setLoading(false);
      })
      .catch((e: Error) => {});
  };

  return (
    <Card className="total-balance-card border-radius-md">
      <h2 className="h-2 font-color-pure-white mt-192 mb-0 border-radius-md">
        {formatMoney(balance)}
      </h2>
      <p className="text font-color-pure-white m-0">Total Balance</p>
    </Card>
  );
};

export default TotalBalance;
