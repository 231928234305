import {
  Card,
  Col,
  Row,
} from "antd";
import React from "react";
import { formatDate } from "../../../../utils";

interface UserItemCardProps {
  user: any;
}

const UserItemCard: React.FC<UserItemCardProps> = ({ user }) => {
  return (
    <>
      <Card className="border-radius-md w-full p-24 antd-card-body-p-0 p-8">
        <Row align={"middle"} justify={"space-between"}>
          <Col xl={4} md={8} xs={24}>
            <p className="text-sm fs-16 m-0">
              <b>ID</b>
            </p>
            <p className="text-sm fs-14 font-color-slate-blue-06 m-0">
              {user.id}
            </p>
          </Col>
          <Col xl={4} md={8} sm={12} xs={24}>
            <p className="text-sm fs-16 m-0">
              <b>Name Surname</b>
            </p>
            <p className="text-sm fs-14 font-color-slate-blue-06 m-0">
              {user.firstName} {user.lastName}
            </p>
          </Col>
          <Col xl={3} md={8} sm={12} xs={24}>
            <p className="text-sm fs-16 m-0">
              <b>Role</b>
            </p>
            <p className="text-sm fs-14 font-color-slate-blue-06 m-0">
              {user.roles[0].name}
            </p>
          </Col>
          <Col xl={3} md={8} sm={12} xs={24}>
            <p className="text-sm fs-16 m-0">
              <b>Email</b>
            </p>
            <p className="text-sm fs-14 font-color-slate-blue-06 m-0">
              {user.email}
            </p>
          </Col>
          <Col xl={3} md={8} sm={12} xs={24}>
            <p className="text-sm fs-16 m-0">
              <b>Mobile Phone</b>
            </p>
            <p className="text-sm fs-14 font-color-slate-blue-06 m-0">
              {user.phoneNumber}
            </p>
          </Col>
          <Col xl={3} md={8} sm={12} xs={24}>
            <p className="text-sm fs-16 m-0">
              <b>Registration Date</b>
            </p>
            <p className="text-sm fs-14 font-color-slate-blue-06 m-0">
              {formatDate(user.createdAt)}
            </p>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default UserItemCard;
