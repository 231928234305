import React, { useEffect, useRef, useState } from "react";
import CustomersStarterCard from "../../components/V2/CustomersV2/CustomersStarterCard";
import {
  Avatar,
  Card,
  Col,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Tooltip,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import CustomersService from "../../services/CustomersService";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { ICustomersResponse } from "../../types/Customers";
import { ColumnProps } from "antd/es/table";
import { Table } from "antd/lib";

const Customers = () => {
  const [customerList, setCustomerList] = useState<ICustomersResponse[]>([]);
  const [totalCustomers, setTotalCustomers] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 10;
  const lastFilterText = `?page=${lastCurrentPage}&size=${lastPageSize}&search=${searchText}`;
  const [filterText, setFilterText] = useState<any>(lastFilterText);
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    newFilterText = newFilterText.replace(/(search=)[^&]*/, `$1${searchText}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line
  }, [currentPage, pageSize, searchText, sortField, sortOrder]);

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchText(value);
    }, 500)
  ).current;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    debouncedSearch(value);
  };

  const getCustomers = () => {
    setLoading(true);
    CustomersService.getCustomers(filterText)
      .then((response: any) => {
        const customers = response.data.data.map(
          (customer: ICustomersResponse) => new ICustomersResponse(customer)
        );
        setCustomerList(customers || []);
        setTotalCustomers(response.data.totalElements);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "ASC" : order === "descend" ? "DESC" : undefined;
    const sortParamRegex = /(&sort=[^&]*)|(&direction=[^&]*)/g;
    let newFilterText = filterText.replace(sortParamRegex, "");
    if (field && direction) {
      newFilterText += `&sort=${field}&direction=${direction}`;
    }
    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";
    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }
    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line
  }, [filterText, sortField, sortOrder]);

  const columns: ColumnProps<ICustomersResponse>[] = [
    {
      title: "Customer",
      dataIndex: "fullName",
      key: "fullName",
      ellipsis: { showTitle: false },
      width: 150,
      sorter: true,
      render: (text) => (
        <Tooltip placement="topLeft" title={`${text}`}>
          <Avatar size={48}>{text?.charAt(0)}</Avatar>
          <p style={{ margin: 0 }}>{text}</p>
        </Tooltip>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 150,
      render: (text) => <span>{text}</span>,
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <CustomersStarterCard />
        </Col>
      </Row>
      <Row className="mt-24">
        <Col span={24}>
          <Card className="bg-cloud border-radius-md px-16">
            <Row gutter={[24, 24]} align={"middle"} justify={"end"}>
              <Col span={8} style={{ textAlign: "right" }}>
                <Input
                  className="amz-input"
                  placeholder="Keyword"
                  prefix={<SearchOutlined className="fs-24 mx-8" />}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Card className="bg-cloud border-radius-md mt-24" loading={loading}>
        <Row justify={"space-between"}>
          <Col xs={24} md={12}>
            <h3 className="h-3 m-0">Customer List</h3>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Table
              loading={loading}
              rowKey={(record) => record?.fullName}
              columns={columns}
              dataSource={customerList}
              bordered
              scroll={{ x: 1000 }}
              pagination={false}
              onChange={handleTableChange}
            />
            <Row style={{ marginTop: "30px" }} justify={"center"}>
              <Pagination
                showSizeChanger={true}
                total={totalCustomers}
                defaultPageSize={pageSize}
                defaultCurrent={0}
                current={currentPage + 1}
                showQuickJumper
                onChange={paginationOnChange}

              />
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Customers;
