import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-type": "application/json",
    Accept: "*/*",
    "x-oneamz-token": localStorage.getItem("externalAccessToken"),
  },
});

// İstek interceptor'ı
http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
      config.headers["x-oneamz-token"] = localStorage.getItem(
        "externalAccessToken"
      );
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Yanıt interceptor'ı
http.interceptors.response.use(
  (response) => {
    // Yanıtları işlemek için
    return response;
  },
  (error) => {
    // Hata işleme
    return Promise.reject(error);
  }
);

export default http;
