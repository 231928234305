import { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  PaginationProps,
  Tooltip,
  Pagination,
  Input,
  Button,
  Popconfirm,
  MenuProps,
  Dropdown,
} from "antd";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import CreateAdditionalService from "../components/AdditionalService/CreateAdditionalService";
import SettingsRouter from "../components/Settings/SettingsRouter";
import SettingsCard from "../components/V2/SettingsV2/SettingsCard";
import AdditionalServiceService from "../services/AdditionalServiceService";
import { IAdditionalServiceResponse } from "../types/AdditionalServices";
import { useLocation } from "react-router-dom";
import { ColumnProps } from "antd/es/table";
import { formatMoney, openNotification } from "../utils";
import { Table } from "antd/lib";
import { debounce } from "lodash";
import AdditionalServiceDetail from "../components/V2/SettingsV2/AdditionalServices/AdditionalServiceDetail";

const AdditionalServices = () => {
  const [additionalServiceList, setAdditionalServiceList] = useState<
    IAdditionalServiceResponse[]
  >([]);
  const [totalAdditionalServices, setTotalAdditionalServices] =
    useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 10;
  const lastFilterText = `?page=${lastCurrentPage}&size=${lastPageSize}&search=${searchText}`;
  const [filterText, setFilterText] = useState<any>(lastFilterText);
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedAdditionalService, setSelectedAdditionalService] =
    useState<any>(null);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    newFilterText = newFilterText.replace(/(search=)[^&]*/, `$1${searchText}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line
  }, [currentPage, pageSize, searchText, sortField, sortOrder]);

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchText(value);
    }, 500)
  ).current;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    debouncedSearch(value);
  };

  const getAdditionalServices = () => {
    setLoading(true);
    AdditionalServiceService.getAdditionalServices(filterText)
      .then((response: any) => {
        const additionalServices = response.data.data.map(
          (additionalService: IAdditionalServiceResponse) =>
            new IAdditionalServiceResponse(additionalService)
        );
        setAdditionalServiceList(additionalServices || []);
        setTotalAdditionalServices(response.data.totalElements);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "ASC" : order === "descend" ? "DESC" : undefined;
    const sortParamRegex = /(&sort=[^&]*)|(&direction=[^&]*)/g;
    let newFilterText = filterText.replace(sortParamRegex, "");
    if (field && direction) {
      newFilterText += `&sort=${field}&direction=${direction}`;
    }
    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";
    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }
    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  useEffect(() => {
    getAdditionalServices();
    // eslint-disable-next-line
  }, [filterText, sortField, sortOrder]);

  const deleteAdditionalService = (id: any) => {
    setLoading(true);
    AdditionalServiceService.deleteAdditionalService(id)
      .then((response: any) => {
        openNotification("success", "Success", "Additional Service Deleted");
        getAdditionalServices();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const showDetailModal = (additionalService: any) => {
    setSelectedAdditionalService(additionalService);
    setIsModalVisible(true);
  };

  const columns: ColumnProps<IAdditionalServiceResponse>[] = [
    {
      title: "Additional Service Name",
      dataIndex: "name",
      key: "name",
      ellipsis: { showTitle: false },
      width: 150,
      sorter: true,
      align: "left",
      render: (text) => (
        <Tooltip placement="topLeft" title={`${text}`}>
          <p style={{ margin: 0 }}>{text}</p>
        </Tooltip>
      ),
    },
    {
      title: "Required",
      dataIndex: "required",
      key: "required",
      width: 50,
      align: "center",
      render: (text) => <span>{text === false ? "No" : "Yes"}</span>,
    },
    {
      title: "Service Type",
      dataIndex: "type",
      key: "type",
      width: 50,
      align: "center",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Unit Price",
      dataIndex: "price",
      key: "price",
      width: 50,
      align: "center",
      render: (text) => <span>{formatMoney(text)}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 30,
      render: (text, record) => {
        const items: MenuProps["items"] = [
          {
            key: "detail",
            label: (
              <Button
                type="default"
                className="font-color-slate-blue btn-text"
                onClick={() => showDetailModal(record)}
              >
                Detail
              </Button>
            ),
          },
          {
            key: "delete",
            label: (
              <Popconfirm
                title="Delete Additional Service"
                description="Are you sure you want to delete the additional service?"
                onConfirm={() => deleteAdditionalService(record?.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  danger
                  className="font-color-slate-blue btn-text"
                  loading={loading}
                >
                  Delete
                </Button>
              </Popconfirm>
            ),
          },
        ];
        return (
          <Dropdown placement="topRight" menu={{ items }}>
            <EllipsisOutlined
              rotate={90}
              style={{ fontSize: "24px", cursor: "pointer" }}
            />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <SettingsCard />
      <SettingsRouter />
      <Row>
        <Col span={24}>
          <Card loading={loading} className="bg-cloud border-radius-md">
            <Row className="mb-24" align={"middle"} justify={"space-between"}>
              <Col span={8}>
                <Input
                  className="amz-input"
                  placeholder="Keyword"
                  prefix={<SearchOutlined className="fs-24 mx-8" />}
                  onChange={handleChange}
                />
              </Col>
              <Col span={5}>
                <CreateAdditionalService
                  onHandleServiceList={getAdditionalServices}
                />
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Table
                  loading={loading}
                  rowKey={(record) => record?.id || ""}
                  columns={columns}
                  dataSource={additionalServiceList}
                  bordered
                  scroll={{ x: 1000 }}
                  pagination={false}
                  onChange={handleTableChange}
                />
                <Row style={{ marginTop: "30px" }} justify={"center"}>
                  <Pagination
                    showSizeChanger={true}
                    total={totalAdditionalServices}
                    defaultPageSize={pageSize}
                    defaultCurrent={0}
                    current={currentPage + 1}
                    showQuickJumper
                    onChange={paginationOnChange}
    
                  />
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {isModalVisible && (
        <AdditionalServiceDetail
          additionalService={selectedAdditionalService}
          getAdditionalServices={getAdditionalServices}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
};

export default AdditionalServices;
