import React, { useState } from "react";
import { Button, Card, Col, Divider, Row } from "antd";
import { ReactComponent as UserIcon } from "../../../assets/icons/User.svg";
import FBAShipmentCostDetail from "./FBAShipmentCostDetail";
import FBMShipmentItemCardManager from "./FBMShipmentCardManager";
import { IFbmOrderResponse } from "../../../types/FbmOrder";
import AddNoteModal from "../../../components/Note/AddNoteModal";

interface OrderDetailCardProps {
  order: IFbmOrderResponse;
}

const FBMShipmentDetail: React.FC<OrderDetailCardProps> = ({ order }) => {
  const [isNoteModalVisible, setIsNoteModalVisible] = useState<boolean>(false);
  const [notes, setNotes] = useState(order?.notes || []);

  const showNoteModal = () => {
    setIsNoteModalVisible(true);
  };

  const handleNoteModalClose = () => {
    setIsNoteModalVisible(false);
  };

  const handleNoteAdded = (newNote: string) => {
    const newNoteObject = {
      notes: newNote,
      user: order?.user,
      createdAt: new Date().toISOString(),
    };
  
    const updatedNotes = [...notes, newNoteObject];
    setNotes(updatedNotes);
  };

  return (
    <Row justify={"space-between"}>
      <Col xl={8} xs={24}>
        <Row>
          <Col>
            <h3 className="h-3 mt-24">User Information</h3>
          </Col>
        </Row>
        <Card className="bg-slate-blue border-radius-md">
          <Row>
            <Col span={4}>
              <UserIcon />
            </Col>
            <Col span={20}>
              <h2 className="font-color-pure-white mt-0 mb-0">
                <b>
                  {order?.user?.firstName} {order?.user?.lastName}
                </b>
              </h2>
              <h2 className="font-color-pure-white mt-0">
                <b>#{order?.user?.id} </b>
              </h2>
              <p className="text font-color-pure-white mt-24 mb-0">
                {order?.user?.phoneNumber}
              </p>
              <p className="text font-color-pure-white mt-0 mb-0">
                {order?.user?.email}
              </p>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col span={24}>
            <Card className="bg-cloud border-radius-md mt-24">
              <Row>
                <Col span={24}>
                <Row justify={"space-between"} align={"middle"}>
                    <h3 className="text font-color-onyx mt-0">
                      <b>Note</b>
                    </h3>
                    <Button
                      className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-8"
                      onClick={showNoteModal}
                    >
                      Add Note
                    </Button>
                    <AddNoteModal
                      orderId={order?.id}
                      visible={isNoteModalVisible}
                      onClose={handleNoteModalClose}
                      onNoteAdded={handleNoteAdded}
                    />
                  </Row>
                  <p className="text font-color-onyx m-0">
                    {order?.notes?.map((note, index) => (
                      <span key={index}>{note?.notes}</span>
                    ))}
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col>
        <Divider type="vertical" className="vertical-divider" />
      </Col>
      <Col xl={15} xs={24}>
        <Row>
          <Col>
            <h3 className="h-3 mt-24">Package Details</h3>
          </Col>
          <Col span={24}>
            {order?.items.map((item: any, index: any) => (
              <FBMShipmentItemCardManager
                item={item}
                key={index}
                order={order}
                setQty={() => {}}
                orderItem={item}
              />
            ))}
          </Col>
          <Col span={24}>
            <FBAShipmentCostDetail order={order}/>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FBMShipmentDetail;
