import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Image,
  Empty,
  Radio,
  Alert,
  Switch,
  Divider,
  Avatar,
  Tooltip,
} from "antd";
import ServiceList from "./ServiceList";
import OneamzAppService from "../../services/OneamzAppService";
import AmazonFba from "../../assets/amazon-fba.png";
import OneamzShip from "../../assets/Logo.svg";
import type { RadioChangeEvent } from "antd";
import { formatMoney, handlePacking } from "../../utils";
import CargoLabelUpload from "./CargoLabelUpload";
import { ReactComponent as InfoIcon } from "../../assets/info-icon.svg";
import { ReactComponent as InfoIconWhite } from "../../assets/info-icon-white.svg";
import AmazonProductImage from "../Common/AmazonProductImage";
import AmazonProductInfo from "../Common/AmazonProductInfo";

interface FBACreateStepProps {
  setServiceQty: (productId: any, serviceId: any, qty: any) => void;
  changeShipmentCompany: (id: any) => void;
  changeShipmentFee: (fee: any) => void;
  setPackageCount: (id: any) => void;
  setCargoLabel: (file: any) => void;
  cargoLabel: null;
  country: null;
  productList: [];
  fbaServices: any[];
  totalFulfillmentCost: 0;
  totalCustomServiceCost: 0;
  totalFBACost: any;
  totalFulfillmentCostPerUnit: 0;
  orderServices: [];
}

const FBACreateStep2: React.FC<FBACreateStepProps> = ({
  setServiceQty,
  changeShipmentCompany,
  changeShipmentFee,
  setPackageCount,
  setCargoLabel,
  cargoLabel,
  country,
  productList,
  fbaServices,
  totalFulfillmentCost,
  totalCustomServiceCost,
  totalFBACost,
  totalFulfillmentCostPerUnit,
  orderServices,
}) => {
  const [shipmentCompany, setShipmentCompany] = useState("AMAZON");
  const [shipmentFee, setShipmentFee] = useState(0);
  const [shipmentOffer, setShipmentOffer] = useState(0);
  const [loadingShipmentOffer, setLoadingShipmentOffer] = useState(false);

  const calcShipmentFee = (country: any) => {
    setLoadingShipmentOffer(true);
    const items = productList.map((product: any) => ({
      asin: product.asin,
      quantity: product.qty,
    }));

    const data = {
      country: country,
      deliveryOption: "STANDARD",
      productList: items,
    };
    OneamzAppService.calcShipmentFee(data)
      .then((response: any) => {
        let shippingFee: any = parseFloat(
          response.data.totalShippingFee.toFixed(2)
        );
        if (country !== "US") {
          setShipmentFee(shippingFee);
          setShipmentOffer(shippingFee);
          changeShipmentFee(shippingFee);
          setLoadingShipmentOffer(false);
          setShipmentCompany("ONEAMZ");
          changeShipmentCompany("ONEAMZ");
        } else {
          setShipmentOffer(shippingFee);
          setLoadingShipmentOffer(false);
        }
      })
      .catch((e: Error) => {
        setShipmentFee(0);
        setShipmentOffer(0);
        changeShipmentFee(0);
        setLoadingShipmentOffer(false);
      });
  };

  useEffect(() => {
    calcShipmentFee(country);
    if (country !== "US") {
      setShipmentCompany("ONEAMZ");
    }
    const boxes = handlePacking(productList);
    setPackageCount(boxes.length);
    // eslint-disable-next-line
  }, []);

  const onChange = (e: RadioChangeEvent) => {
    if (e.target.value === "AMAZON") {
      changeShipmentFee(0);
      setShipmentFee(0);
    } else {
      changeShipmentFee(shipmentOffer);
      setShipmentFee(shipmentOffer);
    }
    setShipmentCompany(e.target.value);
    changeShipmentCompany(e.target.value);
  };

  const onChangeFNSKULabel = (
    product: any,
    service: number,
    checked: boolean
  ) => {
    if (checked) {
      setServiceQty(product.id, service, product.qty);
    } else {
      setServiceQty(product.id, service, 0);
    }
  };

  return (
    <>
      <Card className="bg-cloud border-radius-md mt-24">
        {productList.map((item: any, key) => (
          <Card className="border-radius-md w-full mb-24" key={key}>
            <Row align={"top"} justify={"space-between"} gutter={[0, 24]}>
              <Col span={9}>
                <Row gutter={[24, 24]}>
                  <Col>
                    <AmazonProductImage asin={item?.asin} />
                  </Col>
                  <Col span={16}>
                    <AmazonProductInfo asin={item?.asin} title={item?.name} />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <p className="text-sm fs-12 m-0">
                      <b>DIMENSIONS</b>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="mt-8 text-sm fs-12 font-color-slate-blue-06 mb-0">
                      {item?.length} x {item?.width} x {item?.height} in
                      <br />
                      {item?.weight} lb
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={2} style={{ textAlign: "left" }}>
                <Row justify={"center"}>
                  <Col>
                    <p className="text-sm fs-12 m-0">
                      <b>QUANTITY</b>
                    </p>
                  </Col>
                </Row>
                <Row justify={"center"}>
                  <Col>
                    <p className="mt-8 text-sm fs-12 font-color-slate-blue-06 mb-0">
                      {item?.qty}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={6}>
                {item?.services.map(
                  (service: any, key: any) =>
                    service?.required === false &&
                    service?.id === 1 && (
                      <React.Fragment key={key}>
                        <Row justify={"center"}>
                          <Col>
                            <p className="text-sm fs-12 m-0">
                              <b>
                                {service?.name}
                                <Tooltip
                                  title={
                                    "FNSKU is the Fulfillment Network Stock Keeping Unit. It is a barcode that Amazon has implemented to track products in fulfillment centers. Several sellers may sell the same product, so products can be easily confused if they do not have unique identifiers."
                                  }
                                >
                                  <InfoIcon className="wh-12 ml-8" />
                                </Tooltip>
                              </b>
                            </p>
                          </Col>
                        </Row>
                        <Row justify={"center"}>
                          <Col>
                            {service?.id === 1 && (
                              <>
                                <Switch
                                  className="switch-bg switch-slate-blue mt-8"
                                  defaultChecked={service.qty > 0}
                                  onChange={(value: any) =>
                                    onChangeFNSKULabel(item, service.id, value)
                                  }
                                />
                              </>
                            )}
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                )}
              </Col>
              <Col>
                <ServiceList setQty={setServiceQty} product={item} />
              </Col>
            </Row>
          </Card>
        ))}
        {productList.length === 0 ? (
          <Empty
            description="Add product"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : (
          <></>
        )}
      </Card>
      <Row justify={"center"} className="mt-48" gutter={[24, 24]}>
        <Col xs={24} sm={24} md={8} span={8}>
          <Card
            className="border-radius-md border-slate-blue-02"
            loading={loadingShipmentOffer}
          >
            <Radio.Group
              className="w-full"
              onChange={onChange}
              value={shipmentCompany}
            >
              {country === "US" && (
                <Row align={"middle"} justify={"space-between"}>
                  <Col>
                    <Radio
                      className="amz-radio border-slate-blue-02 border-radius-lg"
                      value="amazon"
                    />
                  </Col>
                  <Col>
                    <Avatar
                      size={48}
                      src={
                        <>
                          <Image preview={false} src={AmazonFba} />
                        </>
                      }
                      className="border-slate-blue-02 pt-4 border-radius-sm"
                    />
                  </Col>
                  <Col lg={8} md={24}>
                    <p className="table-title m-0">COUNTRY</p>
                    <p className="text fs-12 font-color-slate-blue-05 m-0">
                      {country}
                    </p>
                  </Col>
                  <Col lg={8} md={24}>
                    <p className="table-title m-0">TOTAL AMOUNT</p>
                    <p className="text fs-12 font-color-slate-blue-05 m-0">
                      $0
                    </p>
                  </Col>
                  <Col lg={8} md={24}>
                    <p className="table-title m-0">SHIPPING LABEL</p>
                    <CargoLabelUpload
                      setLabel={setCargoLabel}
                      label={cargoLabel}
                    />
                  </Col>
                </Row>
              )}
              {shipmentOffer !== 0 && (
                <>
                  {country === "US" && <Divider className="bg-slate-blue-02" />}
                  <Row align={"middle"} justify={"space-between"}>
                    <Col>
                      <Radio
                        className="amz-radio border-slate-blue-02 border-radius-lg"
                        value="ONEAMZ"
                      />
                    </Col>
                    <Col>
                      <Avatar
                        size={48}
                        src={
                          <>
                            <Image preview={false} src={OneamzShip} />
                          </>
                        }
                        className="border-slate-blue-02 border-radius-sm"
                      />
                    </Col>
                    <Col>
                      <p className="table-title m-0">COUNTRY</p>
                      <p className="text fs-12 font-color-slate-blue-05 m-0">
                        {" "}
                        {country}
                      </p>
                    </Col>
                    <Col>
                      <p className="table-title m-0">TOTAL AMOUNT</p>
                      <p className="text fs-12 font-color-slate-blue-05 m-0">
                        ${shipmentOffer}
                      </p>
                    </Col>
                    <Col>
                      <p
                        className="table-title m-0"
                        style={{ width: "90px" }}
                      ></p>
                    </Col>
                  </Row>
                </>
              )}
            </Radio.Group>
            {shipmentOffer === 0 &&
              country !== "US" &&
              !loadingShipmentOffer && (
                <Alert
                  description="No shipping offers received"
                  type="warning"
                  showIcon
                />
              )}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={10} span={10}>
          <Card className="bg-cloud border-radius-md">
            <h4 className="text font-color-slate-blue fs-14 mt-0">
              <b>
                FBA SERVICES
                <Tooltip title="FBA SERVICES">
                  <InfoIcon className="wh-12 ml-8" />
                </Tooltip>
              </b>
            </h4>
            <Row>
              <Col span={9}>
                <p className="text-small font-color-slate-blue">
                  <b>Service</b>
                </p>
              </Col>
              <Col className="text-center" span={5}>
                <p className="text-small font-color-slate-blue">
                  <b>Unit Price</b>
                </p>
              </Col>
              <Col className="text-center" span={5}>
                <p className="text-small font-color-slate-blue">
                  <b>Quantity</b>
                </p>
              </Col>
              <Col className="text-center" span={5}>
                <p className="text-small font-color-slate-blue">
                  <b>Total</b>
                </p>
              </Col>
            </Row>
            {fbaServices.map(
              (service: any, index) =>
                service?.qty > 0 && (
                  <React.Fragment key={index}>
                    <Row>
                      <Col span={9}>
                        <p className="text-small font-color-slate-blue-05">
                          <b>{service?.name}</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue-05">
                          <b>{formatMoney(service?.price)}</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue-05">
                          <b>{service?.qty}</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue">
                          <b>{formatMoney(service?.qty * service?.price)}</b>
                        </p>
                      </Col>
                    </Row>
                    <Divider className="m-0" />
                  </React.Fragment>
                )
            )}
            {orderServices.map(
              (service: any, index) =>
                service?.qty > 0 && (
                  <React.Fragment key={index}>
                    <Row>
                      <Col span={9}>
                        <p className="text-small font-color-slate-blue-05">
                          <b>{service?.name}</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue-05">
                          <b>{formatMoney(service?.price)}</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue-05">
                          <b>{service?.qty}</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue">
                          <b>{formatMoney(service?.qty * service?.price)}</b>
                        </p>
                      </Col>
                    </Row>
                    <Divider className="m-0" />
                  </React.Fragment>
                )
            )}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={6} span={6}>
          <Card className="border-cloud border-radius-md antd-card-body-p-0">
            <h4 className="text font-color-slate-blue fs-14 mx-24 mt-24">
              <b>
                AMAZON FBA COST
                <Tooltip title="AMAZON FBA COST">
                  <InfoIcon className="wh-12 ml-8" />
                </Tooltip>
              </b>
            </h4>
            <Row className="mx-24" justify={"space-between"}>
              <Col>
                <p className="text-small font-color-slate-blue-05">
                  <b>Transportation Cost</b>
                </p>
              </Col>
              <Col>
                <p className="text-small font-color-slate-blue">
                  <b>{formatMoney(shipmentFee)}</b>
                </p>
              </Col>
            </Row>
            <div className="mx-24">
              <Divider className="bg-cloud m-0" />
            </div>
            <Row className="mx-24" justify={"space-between"}>
              <Col>
                <p className="text-small font-color-slate-blue-05">
                  <b>FBA Total Compulsory Services</b>
                </p>
              </Col>
              <Col>
                <p className="text-small font-color-slate-blue">
                  <b>{formatMoney(totalFBACost)}</b>
                </p>
              </Col>
            </Row>
            <div className="mx-24">
              <Divider className="bg-cloud m-0" />
            </div>
            <Row className="mx-24" justify={"space-between"}>
              <Col>
                <p className="text-small font-color-slate-blue-05">
                  <b>FBA Additional Services Total</b>
                </p>
              </Col>
              <Col>
                <p className="text-small font-color-slate-blue">
                  <b>{formatMoney(totalCustomServiceCost)}</b>
                </p>
              </Col>
            </Row>
            <div className="mx-24">
              <Divider className="bg-cloud m-0" />
            </div>
            <Row className="mx-24" justify={"space-between"}>
              <Col>
                <p className="text-small font-color-slate-blue-05">
                  <b>Cost per Product</b>
                </p>
              </Col>
              <Col>
                <p className="text-small font-color-slate-blue">
                  <b>{formatMoney(totalFulfillmentCostPerUnit)}</b>
                </p>
              </Col>
            </Row>
            <Row
              className="bottom-border-radius-md bg-slate-blue p-24"
              justify={"space-between"}
              align={"middle"}
            >
              <Col>
                <h4 className="text font-color-cloud fs-14 m-0">
                  <b>
                    TOTAL COST
                    <Tooltip className="font-color-cloud" title="TOTAL COST">
                      <InfoIconWhite className="wh-12 ml-8" />
                    </Tooltip>
                  </b>
                </h4>
              </Col>
              <Col>
                <h3 className="h-3 font-color-cloud m-0">
                  {formatMoney(totalFulfillmentCost)}
                </h3>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default FBACreateStep2;
