import { Button, Col, Form, Input, Modal, Row, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import closeIcon from "../../../../assets/XCircle.svg";
import { openNotification } from "../../../../utils";
import AdditionalServiceService from "../../../../services/AdditionalServiceService";
import { IAdditionalServiceUpdateRequest } from "../../../../types/AdditionalServices";

interface AdditionalServiceDetailProps {
  additionalService: any;
  getAdditionalServices: () => void;
  open: boolean;
  onCancel: () => void;
}

const { Item } = Form;

const AdditionalServiceDetail: React.FC<AdditionalServiceDetailProps> = ({
  additionalService,
  getAdditionalServices,
  open,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [required, setRequired] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open && additionalService) {
      form.setFieldsValue({
        name: additionalService.name,
        type: additionalService.type,
        price: additionalService.price,
        required: additionalService.required,
      });
      setRequired(additionalService.required);
    }
  }, [open, additionalService, form]);

  const updateAdditionalService = (values: any) => {
    setLoading(true);
    let postObj: IAdditionalServiceUpdateRequest = {
      name: values.name,
      type: values.type,
      price: values.price,
      required: values.required,
    };

    AdditionalServiceService.updateAdditionalService(
      additionalService.id,
      postObj
    )
      .then(() => {
        getAdditionalServices();
        onCancel();
        openNotification("success", "Success", "Service successfully updated");
      })
      .catch((e: Error) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title={<>{additionalService?.name}</>}
      open={open}
      className="antd-modal-secondary"
      onCancel={onCancel}
      footer={null}
      closeIcon={<img src={closeIcon} height={24} alt="close" />}
    >
      <Form form={form} onFinish={updateAdditionalService} layout="vertical">
        <Item
          name="name"
          label="Additional Service Name"
          rules={[
            {
              required: true,
              message: "Additional Service name required",
            },
          ]}
        >
          <Input />
        </Item>
        <Item
          name="type"
          label="Service Type"
          rules={[
            {
              required: true,
              message: "Service Type required",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            options={[
              { value: "FBA", label: "FBA" },
              { value: "FBM", label: "FBM" },
            ]}
          />
        </Item>
        <Item
          name="price"
          label="Unit Price"
          rules={[
            {
              required: true,
              message: "Unit Price required",
            },
          ]}
        >
          <Input />
        </Item>
        <Row gutter={24}>
          <Col>
            <Item name="required" label="Required">
              <Switch
                checked={required}
                onChange={() => setRequired(!required)}
              />
            </Item>
          </Col>
        </Row>
        <Item>
          <Row gutter={24}>
            <Col span={12}>
              <Button
                className="btn btn-text btn-hover-dark font-color-slate-blue border-slate-blue w-full border-radius-8"
                onClick={onCancel}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="btn btn-text btn-hover-white font-color-cloud bg-slate-blue w-full border-radius-8"
                htmlType="submit"
                loading={loading}
              >
                Update
              </Button>
            </Col>
          </Row>
        </Item>
      </Form>
    </Modal>
  );
};

export default AdditionalServiceDetail;
