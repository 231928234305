import React from "react";
import { Card, Col, Divider, Row } from "antd";
import { ReactComponent as WarehouseIcon } from "../../../assets/V2/OrderDetailV2/warehouse-icon.svg";
import { ReactComponent as HouseCheckIcon } from "../../../assets/V2/OrderDetailV2/house-check.svg";
import OrderDetailPackageInfo from "../../../components/V2/OrderDetailV2/OrderDetailPackageInfo";
import FBAShipmentCostDetail from "./FBAShipmentCostDetail";
import OrderDetailLabels from "../../../components/V2/OrderDetailV2/OrderDetailLabels";
import FBAShipmentItemCard from "./FBAShipmentItemCard";
import { CopyClipboard } from "../../../components/FBACreate/CopyClipboard";
import { IFbaOrderResponse } from "../../../types/FbaOrder";

interface OrderDetailCardProps {
  order: IFbaOrderResponse;
  setQty: (id: any, qty: any) => void;
}

const FBAShipmentDetail: React.FC<OrderDetailCardProps> = ({
  order,
  setQty,
}) => {
  return (
    <Row justify={"space-between"}>
      <Col xl={8} xs={24}>
        <Row>
          <Col>
            <h3 className="h-3">Shipment Details</h3>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card className="bg-slate-blue border-radius-md">
              <Row>
                <Col span={4}>
                  <WarehouseIcon style={{ height: "48px", width: "48px" }} />
                </Col>
                <Col span={20}>
                  <p className="text font-color-pure-white">
                    <b>Sent to Amazon Warehouse</b>
                  </p>
                  <p className="pretitle font-color-pure-white">
                    {order?.warehouse?.countryCode}
                  </p>
                  <p className="pretitle font-color-pure-white">
                    {order?.warehouse?.address}
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row gutter={24} className="mt-24">
          <Col span={24}>
            <Card className="bg-cloud border-radius-md">
              <Row>
                <Col span={4}>
                  <HouseCheckIcon className="wh-48" />
                </Col>
                <Col span={20}>
                  <p className="text font-color-onyx">
                    <b>OneAmz Warehouse Address</b>
                  </p>
                  <p className="text font-color-onyx m-0">
                    <CopyClipboard text={"356 GETTY AVE"} />
                  </p>
                  <p className="text font-color-onyx m-0">
                    <CopyClipboard text={`BLD-5A #${order?.orderCode}`} />
                  </p>
                  <p className="text font-color-onyx m-0">
                    <CopyClipboard text={"CLIFTON, NJ 07011-2118"} />
                  </p>
                  <p className="text font-color-onyx m-0">
                    <CopyClipboard text={"United States"} />
                  </p>
                  <p className="text font-color-onyx mb-0">
                    <b>Phone:</b>
                  </p>
                  <p className="text font-color-onyx mt-0">
                    <CopyClipboard text={"917 306 6033"} />
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <OrderDetailLabels order={order}/>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card className="bg-cloud border-radius-md mt-24">
              <Row>
                <Col span={24}>
                  <h3 className="text font-color-onyx mt-0">
                    <b>Note</b>
                  </h3>
                  <p className="text font-color-onyx m-0">
                    {order?.notes?.map((note, index) => (
                      <span key={index}>{note?.notes}</span>
                    ))}
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col>
        <Divider type="vertical" className="vertical-divider" />
      </Col>
      <Col xl={15} xs={24}>
        <Row>
          <Col>
            <h3 className="h-3 mt-24">Package Details</h3>
          </Col>
          <Col span={24}>
            {order?.items.map((item: any, index: any) => (
              <FBAShipmentItemCard
                item={item}
                key={index}
                order={order}
                orderItem={item}
              />
            ))}
          </Col>
          <Col span={24}>
            <OrderDetailPackageInfo order={order}/>
          </Col>
          <Col span={24}>
            <FBAShipmentCostDetail order={order}/>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FBAShipmentDetail;
